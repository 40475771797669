import React, { Component } from "react";
import logo from "../../assets/images/logo.png";
import "./Header.css";
import { ReactComponent as DownSvg } from "../../assets/images/down.svg";
import { BiCaretDown, BiLogOut } from "react-icons/bi";
import { GoPerson } from "react-icons/go";
import qs from "qs";
import axios from "axios";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { BsCreditCard } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./sideBar";
import "./home.css";
import close from "../../assets/images/close.png";
toast.configure();
export default class Header extends Component {
  dropdownMenu = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      token: "",
      open: false,
      isHomeActive: false,
      isPricingActive: false,
      isAllForecastsActive: false,
      isMobileAppActive: false,
      isBactToSurferActive: false,
      isAboutActive: false,
      isSCActive: false,
      showSubscribe: false
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    console.log(localStorage.getItem("isHomeActive"));
    console.log(window.location.pathname);
    console.log(token);
    this.setState({
      name: name,
      email: email,
      token: token,
      isPricingActive:
        window.location.pathname == "/subscription" ? true : false,
      isHomeActive: window.location.pathname == "/" ? true : false,
      isSCActive: window.location.pathname == "/swell_map/socal" ? true : false,
    });
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  goToHome = (history) => {
    this.setState({
      isHomeActive: true,
      isPricingActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
    });
    history.push("/");
  };
  goToSouthernCalifornia = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: true,
    });

    history.push("/swell_map/socal");
    window.location.reload();
  };

  goToPricing = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: true,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
    });
    history.push("/subscription");
  };
  goToAllForecats = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isAllForecastsActive: true,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
    });
    window.open("https://forecast.surfer.com/");
  };
  goToMobileApp = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: true,
      isSCActive: false,
    });
    window.open("https://forecast.surfer.com/mobile-app");
  };
  goToBackToSurfer = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: true,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
    });

    // window.open("https://www.surfer.com/",'_self')
    window.open("https://www.surfer.com/");
  };
  goToAbout = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: true,
      isMobileAppActive: false,
      isSCActive: false,
    });

    window.open("https://forecast.surfer.com/about/", "_blank");
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.dropdownMenu.current &&
      !this.dropdownMenu.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };
  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };
  goToMySubscription = (history) => {
    history.push("/my_subscriptions");
  };
  onLogout = (history) => {
    const url = "https://maps-surfer-api.surfable.co/user/logout";

    const token = localStorage.getItem("token");
    console.log("tokkk", this.state.token);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + this.state.token,
      },
    };

    axios
      .get(url, config)
      .then((result) => {
        console.log(result.status);
        localStorage.clear();

        toast.info("Logged Out Successfully", {
          autoClose: 2000,
        });
        // setTimeout( () => { this.props.history.push("/")},2000);
        if (result.status == 200) {
          localStorage.clear();
          history.push("/");
          this.setState({
            token: null,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          toast.error(`${error.response.data.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        this.setState({
          isLoading: false,
        });
      });
  };
  gotToDashboard = (history) => {
    history.push("/");
  };

  getSubscriptionPage = () => {
    this.setState({
      isLoading: true,
    });

    const url =
      "https://maps-surfer-api.surfable.co/subscription/customerportel";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };

    axios
      .post(url, {}, config)
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data.session;
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          isLoading: false,
          showSubscribe: true
        });
      });
  };

  hideSubscribePop() {
    this.setState({
      showSubscribe: false
    })
  }
  render() {
    return (
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid hsla(120, 60%, 70%, 0.3)",
          position: 'relative',
          zIndex: '2'
        }}
      >
        <div className="navBar-mobile">
          <div className="nav-bar">
            <div className="side-bar" id="outer-container">
              <Sidebar
                pageWrapId={"page-wrap"}
                outerContainerId={"outer-container"}
              />
            </div>
          </div>
        </div>
        <div className="navBar-web">
          <div className="nav-bar-align">
            <Route
              render={({ history }) => (
                <img
                  id="page-wrap"
                  src={logo}
                  className="header-logo"
                  onClick={() => this.gotToDashboard(history)}
                />
              )}
            />
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              {this.state.token == null ? (
                <>
                  <Route
                    render={({ history }) => (
                      <div
                        className={
                          this.state.isHomeActive ? "home" : "inactive-home"
                        }
                        onClick={() => this.goToHome(history)}
                      >
                        Swellmaps
                        <div
                          className={
                            this.state.isHomeActive
                              ? "active-border home-active"
                              : "inactive-border"
                          }
                        ></div>
                      </div>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <div
                        className={
                          this.state.isSCActive ? "home" : "inactive-home"
                        }
                        onClick={() => this.goToSouthernCalifornia(history)}
                      >
                        Southern California
                        <div
                          className={
                            this.state.isSCActive
                              ? "active-border sc-active"
                              : "inactive-border"
                          }
                        ></div>
                      </div>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <div
                        className={
                          this.state.isPricingActive ? "home" : "inactive-home"
                        }
                        onClick={() => this.goToPricing(history)}
                      >
                        Pricing
                        <div
                          className={
                            this.state.isPricingActive
                              ? "active-border pricing-active"
                              : "inactive-border"
                          }
                        ></div>
                      </div>
                    )}
                  />

                  <Route
                    render={({ history }) => (
                      <div
                        className={
                          this.state.isAllForecastsActive
                            ? "home"
                            : "inactive-home"
                        }
                        onClick={() => this.goToAllForecats(history)}
                      >
                        All Forecasts
                        <div
                          className={
                            this.state.isAllForecastsActive
                              ? "active-border all-forecast-active"
                              : "inactive-border"
                          }
                        ></div>
                      </div>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <div
                        className={
                          this.state.isMobileAppActive
                            ? "home"
                            : "inactive-home"
                        }
                        onClick={() => this.goToMobileApp(history)}
                      >
                        Mobile App
                        <div
                          className={
                            this.state.isMobileAppActive
                              ? "active-border mobile-app-active"
                              : "inactive-border"
                          }
                        ></div>
                      </div>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <div
                        className={
                          this.state.isBackToSurferActive
                            ? "home"
                            : "inactive-home"
                        }
                        onClick={() => this.goToBackToSurfer(history)}
                      >
                        Back to Surfer
                        <div
                          className={
                            this.state.isBackToSurferActive
                              ? "active-border all-forecast-active"
                              : "inactive-border"
                          }
                        ></div>
                      </div>
                    )}
                  />

                  <Route
                    render={({ history }) => (
                      <div
                        className={
                          this.state.isAboutActive ? "home" : "inactive-home"
                        }
                        onClick={() => this.goToAbout(history)}
                      >
                        About
                        <div
                          className={
                            this.state.isAboutActive
                              ? "active-border about-active"
                              : "inactive-border"
                          }
                        ></div>
                      </div>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <>
                        <button
                          // style={{ marginLeft: "20px" }}
                          onClick={() => history.push("/register")}
                          className="header-login-Rtr"
                        >
                          Register
                        </button>
                      </>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <>
                        <button
                          className="header-login-Btn"
                          onClick={() => history.push("/login")}
                        >
                          Login
                        </button>
                      </>
                    )}
                  />
                </>
              ) : (
                  <>
                    <Route
                      render={({ history }) => (
                        <div
                          className={
                            this.state.isHomeActive ? "home" : "inactive-home"
                          }
                          onClick={() => this.goToHome(history)}
                        >
                          Swellmaps
                        <div
                            className={
                              this.state.isHomeActive
                                ? "active-border home-active"
                                : "inactive-border"
                            }
                          ></div>
                        </div>
                      )}
                    />
                    <Route
                      render={({ history }) => (
                        <div
                          className={
                            this.state.isSCActive ? "home" : "inactive-home"
                          }
                          onClick={() => this.goToSouthernCalifornia(history)}
                        >
                          Southern California
                        <div
                            className={
                              this.state.isSCActive
                                ? "active-border sc-active"
                                : "inactive-border"
                            }
                          ></div>
                        </div>
                      )}
                    />
                    <Route
                      render={({ history }) => (
                        <div
                          className={
                            this.state.isPricingActive ? "home" : "inactive-home"
                          }
                          onClick={() => this.goToPricing(history)}
                        >
                          Pricing
                        <div
                            className={
                              this.state.isPricingActive
                                ? "active-border pricing-active"
                                : "inactive-border"
                            }
                          ></div>
                        </div>
                      )}
                    />

                    <Route
                      render={({ history }) => (
                        <div
                          className={
                            this.state.isAllForecastsActive
                              ? "home"
                              : "inactive-home"
                          }
                          onClick={() => this.goToAllForecats(history)}
                        >
                          All Forecasts
                        <div
                            className={
                              this.state.isAllForecastsActive
                                ? "active-border all-forecast-active"
                                : "inactive-border"
                            }
                          ></div>
                        </div>
                      )}
                    />
                    <Route
                      render={({ history }) => (
                        <div
                          className={
                            this.state.isMobileAppActive
                              ? "home"
                              : "inactive-home"
                          }
                          onClick={() => this.goToMobileApp(history)}
                        >
                          Mobile App
                        <div
                            className={
                              this.state.isMobileAppActive
                                ? "active-border mobile-app-active"
                                : "inactive-border"
                            }
                          ></div>
                        </div>
                      )}
                    />
                    <Route
                      render={({ history }) => (
                        <div
                          className={
                            this.state.isBackToSurferActive
                              ? "home"
                              : "inactive-home"
                          }
                          onClick={() => this.goToBackToSurfer(history)}
                        >
                          Back to Surfer
                        <div
                            className={
                              this.state.isBackToSurferActive
                                ? "active-border all-forecast-active"
                                : "inactive-border"
                            }
                          ></div>
                        </div>
                      )}
                    />

                    <Route
                      render={({ history }) => (
                        <div
                          className={
                            this.state.isAboutActive ? "home" : "inactive-home"
                          }
                          onClick={() => this.goToAbout(history)}
                        >
                          About
                        <div
                            className={
                              this.state.isAboutActive
                                ? "active-border about-active"
                                : "inactive-border"
                            }
                          ></div>
                        </div>
                      )}
                    />
                    <div className="round-user-icon">
                      <GoPerson
                        // size="30px"
                        className="user-icon"
                        color=" #000000"
                      />
                    </div>

                    <div style={{ flexDirection: "column", marginLeft: "6px" }}>
                      <p className="username">{this.state.name}</p>
                      <p className="userEmail">{this.state.email}</p>
                    </div>

                    <div className="dropdownMenu" ref={this.dropdownMenu}>
                      <div
                        className="select-box"
                        onClick={this.handleButtonClick}
                      >
                        <DownSvg width="13px" className="down-icon" />
                      </div>
                      {this.state.open && (
                        <div className="dropdown-content">
                          <div>
                            <ul>
                              <Route
                                render={({ history }) => (
                                  <>
                                    {window.location.pathname ==
                                      "/my_subscriptions" ? null : (
                                        <li
                                          className="items"
                                          onClick={() =>
                                            this.getSubscriptionPage()
                                          }
                                        >
                                          {" "}
                                          <BsCreditCard
                                            color="#f76c6c"
                                            size="18px"
                                            className="logout-icon"
                                          />
                                          Premium
                                    </li>
                                      )}
                                    <li
                                      className="items"
                                      onClick={() => this.onLogout(history)}
                                    >
                                      {" "}
                                      <BiLogOut
                                        color="#f76c6c"
                                        size="18px"
                                        className="logout-icon"
                                      />
                                      Logout
                                  </li>
                                  </>
                                )}
                              />
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
        {this.state.showSubscribe ? <div className="popup-container" onClick={() => this.hideSubscribePop()}>
          <div className="popup-outer" onClick={(e) => e.stopPropagation()}>
            <img src={close} className="close-icon" onClick={() => this.hideSubscribePop()} />
            <h2>Subscribe Now!</h2>
            <p>7 day surf wave height & period maps for Southern California, Northern California and Hawaii</p>
            <button className="subscribe-btn" onClick={() => window.location.href = '/subscription'}>Subscribe</button>
          </div>
        </div> : null}
      </div>
    );
  }
}

// import React, { Component } from "react";
// import logo from "../../assets/images/logo.png";
// import "./Header.css";
// import { ReactComponent as DownSvg } from "../../assets/images/down.svg";
// import { BiCaretDown, BiLogOut } from "react-icons/bi";
// import { GoPerson } from "react-icons/go";
// import qs from "qs";
// import axios from "axios";
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import ClipLoader from "react-spinners/ClipLoader";
// import { ToastContainer, toast } from "react-toastify";
// import { BsCreditCard } from "react-icons/bs";
// import "react-toastify/dist/ReactToastify.css";
// import Sidebar from "./sideBar";
// import "./home.css";
// toast.configure();
// export default class Header extends Component {
//   dropdownMenu = React.createRef();
//   constructor(props) {
//     super(props);

//     this.state = {
//       name: "",
//       email: "",
//       token: "",
//       open: false,
//       isHomeActive: false,
//       isPricingActive: false,
//       isAllForecastsActive: false,
//       isMobileAppActive: false,
//       isBactToSurferActive: false,
//       isAboutActive: false,
//       isSCActive: false,
//     };
//   }
//   componentDidMount() {
//     const token = localStorage.getItem("token");
//     const name = localStorage.getItem("name");
//     const email = localStorage.getItem("email");
//     console.log(localStorage.getItem("isHomeActive"));
//     console.log(window.location.pathname);
//     console.log(token);
//     this.setState({
//       name: name,
//       email: email,
//       token: token,
//       isPricingActive:
//         window.location.pathname == "/subscription" ? true : false,
//       isHomeActive: window.location.pathname == "/" ? true : false,
//       isSCActive: window.location.pathname == "/swell_map/socal" ? true : false,
//     });
//     document.addEventListener("mousedown", this.handleClickOutside);
//   }
//   goToHome = (history) => {
//     this.setState({
//       isHomeActive: true,
//       isPricingActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//     });
//     history.push("/");
//   };
//   goToSouthernCalifornia = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: true,
//     });

//     history.push("/swell_map/socal");
//     window.location.reload();
//   };

//   goToPricing = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: true,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//     });
//     history.push("/subscription");
//   };
//   goToAllForecats = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isAllForecastsActive: true,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//     });
//     window.open("https://forecast.surfer.com/");
//   };
//   goToMobileApp = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: true,
//       isSCActive: false,
//     });
//     window.open("https://forecast.surfer.com/mobile-app");
//   };
//   goToBackToSurfer = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: true,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//     });

//     // window.open("https://www.surfer.com/",'_self')
//     window.open("https://www.surfer.com/");
//   };
//   goToAbout = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: true,
//       isMobileAppActive: false,
//       isSCActive: false,
//     });

//     window.open("https://forecast.surfer.com/about/", "_blank");
//   };
//   componentWillUnmount() {
//     document.removeEventListener("mousedown", this.handleClickOutside);
//   }
//   handleClickOutside = (event) => {
//     if (
//       this.dropdownMenu.current &&
//       !this.dropdownMenu.current.contains(event.target)
//     ) {
//       this.setState({
//         open: false,
//       });
//     }
//   };
//   handleButtonClick = () => {
//     this.setState((state) => {
//       return {
//         open: !state.open,
//       };
//     });
//   };
//   goToMySubscription = (history) => {
//     history.push("/my_subscriptions");
//   };
//   onLogout = (history) => {
//     const url = "http://surf-maps-bk.devtomaster.com/user/logout";

//     const token = localStorage.getItem("token");
//     console.log("tokkk", this.state.token);

//     const config = {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//         Authorization: "Bearer" + this.state.token,
//       },
//     };

//     axios
//       .get(url, config)
//       .then((result) => {
//         console.log(result.status);
//         localStorage.clear();

//         toast.info("Logged Out Successfully", {
//           autoClose: 2000,
//         });
//         // setTimeout( () => { this.props.history.push("/")},2000);
//         if (result.status == 200) {
//           localStorage.clear();
//           history.push("/");
//           this.setState({
//             token: null,
//           });
//         }
//       })
//       .catch((error) => {
//         if (error.response) {
//           // Request made and server responded
//           console.log(error.response.data);
//           toast.error(`${error.response.data.data}`);
//           console.log(error.response.status);
//           console.log(error.response.headers);
//         } else if (error.request) {
//           // The request was made but no response was received
//           console.log(error.request);
//         } else {
//           // Something happened in setting up the request that triggered an Error
//           console.log("Error", error.message);
//         }
//         this.setState({
//           isLoading: false,
//         });
//       });
//   };
//   gotToDashboard = (history) => {
//     history.push("/");
//   };

//   render() {
//     return (
//       <div
//         style={{
//           width: "100%",
//           borderBottom: "1px solid hsla(120, 60%, 70%, 0.3)",
//         }}
//       >
//         {/* <div className="navBar-mobile">

//          <div className="side-bar" id="outer-container">
//             <Sidebar
//               pageWrapId={"page-wrap"}
//               outerContainerId={"outer-container"}
//             />
//           </div>
//           </div> */}
//         <div className="navBar-web">

//           <div className="nav-bar-align">
//             <Route
//               render={({ history }) => (
//                 <img
//                   id="page-wrap"
//                   src={logo}
//                   className="header-logo"
//                   onClick={() => this.gotToDashboard(history)}
//                 />
//               )}
//             />
//             <div
//               style={{
//                 flexDirection: "row",
//                 display: "flex",
//                 alignItems: "center",
//               }}
//             >
//               {this.state.token == null ? (
//                 <>
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isHomeActive ? "home" : "inactive-home"
//                         }
//                         onClick={() => this.goToHome(history)}
//                       >
//                         Swellmaps
//                         <div
//                           className={
//                             this.state.isHomeActive
//                               ? "active-border home-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isSCActive ? "home" : "inactive-home"
//                         }
//                         onClick={() => this.goToSouthernCalifornia(history)}
//                       >
//                         Southern California
//                         <div
//                           className={
//                             this.state.isSCActive
//                               ? "active-border sc-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isPricingActive ? "home" : "inactive-home"
//                         }
//                         onClick={() => this.goToPricing(history)}
//                       >
//                         Pricing
//                         <div
//                           className={
//                             this.state.isPricingActive
//                               ? "active-border pricing-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />

//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isAllForecastsActive
//                             ? "home"
//                             : "inactive-home"
//                         }
//                         onClick={() => this.goToAllForecats(history)}
//                       >
//                         All Forecasts
//                         <div
//                           className={
//                             this.state.isAllForecastsActive
//                               ? "active-border all-forecast-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isMobileAppActive
//                             ? "home"
//                             : "inactive-home"
//                         }
//                         onClick={() => this.goToMobileApp(history)}
//                       >
//                         Mobile App
//                         <div
//                           className={
//                             this.state.isMobileAppActive
//                               ? "active-border mobile-app-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isBackToSurferActive
//                             ? "home"
//                             : "inactive-home"
//                         }
//                         onClick={() => this.goToBackToSurfer(history)}
//                       >
//                         Back to Surfer
//                         <div
//                           className={
//                             this.state.isBackToSurferActive
//                               ? "active-border all-forecast-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />

//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isAboutActive ? "home" : "inactive-home"
//                         }
//                         onClick={() => this.goToAbout(history)}
//                       >
//                         About
//                         <div
//                           className={
//                             this.state.isAboutActive
//                               ? "active-border about-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <>
//                         <button
//                           // style={{ marginLeft: "20px" }}
//                           onClick={() => history.push("/register")}
//                           className="header-login-Rtr"
//                         >
//                           Register
//                         </button>
//                       </>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <>
//                         <button
//                           className="header-login-Btn"
//                           onClick={() => history.push("/login")}
//                         >
//                           Login
//                         </button>
//                       </>
//                     )}
//                   />
//                 </>
//               ) : (
//                 <>
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isHomeActive ? "home" : "inactive-home"
//                         }
//                         onClick={() => this.goToHome(history)}
//                       >
//                         Swellmaps
//                         <div
//                           className={
//                             this.state.isHomeActive
//                               ? "active-border home-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isSCActive ? "home" : "inactive-home"
//                         }
//                         onClick={() => this.goToSouthernCalifornia(history)}
//                       >
//                         Southern California
//                         <div
//                           className={
//                             this.state.isSCActive
//                               ? "active-border sc-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isPricingActive ? "home" : "inactive-home"
//                         }
//                         onClick={() => this.goToPricing(history)}
//                       >
//                         Pricing
//                         <div
//                           className={
//                             this.state.isPricingActive
//                               ? "active-border pricing-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />

//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isAllForecastsActive
//                             ? "home"
//                             : "inactive-home"
//                         }
//                         onClick={() => this.goToAllForecats(history)}
//                       >
//                         All Forecasts
//                         <div
//                           className={
//                             this.state.isAllForecastsActive
//                               ? "active-border all-forecast-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isMobileAppActive
//                             ? "home"
//                             : "inactive-home"
//                         }
//                         onClick={() => this.goToMobileApp(history)}
//                       >
//                         Mobile App
//                         <div
//                           className={
//                             this.state.isMobileAppActive
//                               ? "active-border mobile-app-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isBackToSurferActive
//                             ? "home"
//                             : "inactive-home"
//                         }
//                         onClick={() => this.goToBackToSurfer(history)}
//                       >
//                         Back to Surfer
//                         <div
//                           className={
//                             this.state.isBackToSurferActive
//                               ? "active-border all-forecast-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />

//                   <Route
//                     render={({ history }) => (
//                       <div
//                         className={
//                           this.state.isAboutActive ? "home" : "inactive-home"
//                         }
//                         onClick={() => this.goToAbout(history)}
//                       >
//                         About
//                         <div
//                           className={
//                             this.state.isAboutActive
//                               ? "active-border about-active"
//                               : "inactive-border"
//                           }
//                         ></div>
//                       </div>
//                     )}
//                   />
//                   <div className="round-user-icon">
//                     <GoPerson
//                       // size="30px"
//                       className="user-icon"
//                       color=" #000000"
//                     />
//                   </div>

//                   <div style={{ flexDirection: "column", marginLeft: "6px" }}>
//                     <p className="username">{this.state.name}</p>
//                     <p className="userEmail">{this.state.email}</p>
//                   </div>

//                   <div className="dropdownMenu" ref={this.dropdownMenu}>
//                     <div
//                       className="select-box"
//                       onClick={this.handleButtonClick}
//                     >
//                       <DownSvg width="13px" className="down-icon" />
//                     </div>
//                     {this.state.open && (
//                       <div className="dropdown-content">
//                         <div>
//                           <ul>
//                             <Route
//                               render={({ history }) => (
//                                 <>
//                                   {window.location.pathname ==
//                                   "/my_subscriptions" ? null : (
//                                     <li
//                                       className="items"
//                                       onClick={() =>
//                                         this.goToMySubscription(history)
//                                       }
//                                     >
//                                       {" "}
//                                       <BsCreditCard
//                                         color="#f76c6c"
//                                         size="18px"
//                                         className="logout-icon"
//                                       />
//                                       My Subscriptions
//                                     </li>
//                                   )}
//                                   <li
//                                     className="items"
//                                     onClick={() => this.onLogout(history)}
//                                   >
//                                     {" "}
//                                     <BiLogOut
//                                       color="#f76c6c"
//                                       size="18px"
//                                       className="logout-icon"
//                                     />
//                                     Logout
//                                   </li>
//                                 </>
//                               )}
//                             />
//                           </ul>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
