import React, { Component } from "react";
import Header from "../Header";
import "../SubscriptionPage.css";
import "./Stripe.css";
import { ReactComponent as BackArrowImage } from "../../../assets/images/backArrow.svg";
import left_arrow from "../../../assets/images/left-arrow.svg";
import { AiFillCheckCircle } from "react-icons/ai";
import qs from "qs";
import {Helmet} from "react-helmet";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./stripe-checkout.css";

const stripePromise = loadStripe(
  "pk_live_51HkizGDR9BvXcM3BSTVSBPpbRs75hQQeu9qqXzyAJFGuFRvLUs6egnOCDY7OR176OL9xAc19OX5QwcnnnBiuOdZp00eTfUSaBE"
);


export default class Stripe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      planDetails: [],
    };
    this.goBack = this.goBack.bind(this);
  }
  componentDidMount() {
    this.setState({
      token: localStorage.getItem("token"),
    });
    this.getAllPlans();
  }
  getAllPlans = () => {
    const url = "https://maps-surfer-api.surfable.co/subscription/allplans";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };
    console.log(config);

    axios
      .get(url, config)
      .then((result) => {
        console.log(result);
        this.setState({
          planDetails: result.data.data,
        });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          // toast.error(`${error.response.data.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          // toast.error("Oops Something Went Wrong");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // toast.error("Oops Something Went Wrong");
        }

        this.setState({
          isLoading: false,
        });
      });
  };
  goBack() {
    this.props.history.goBack();
  }

  renderLocation(){
    if(this.props.match.params.plan=='Free Trial') {
    return   <title>Free Trial | SURFERforecast Maps</title>;    
    } else if (this.props.match.params.plan=='month'){
      return <title>Monthly Subscription | SURFERforecast Maps</title>; 

    } else if (this.props.match.params.plan=='year'){
      return <title>Annual Subscription | SURFERforecast Maps</title>; 

    } 
  }
  renderMeta(){
    if(this.props.match.params.plan=='Free Trial') {
      return   <meta name='description' content='SURFER Forecast Premium Access Free Trial'></meta>;    
      } else if (this.props.match.params.plan=='month'){
        return <meta name='description' content='SURFER Forecast Premium Monthly Access Pricing'></meta>;
  
      } else if (this.props.match.params.plan=='year'){
        return <meta name='description' content='SURFER Forecast Premium Annual Subscription Pricing'></meta>;
  
      } }

  render() {
    return (
      <div>
        <Helmet>
          {this.renderLocation()}
          {this.renderMeta()}
        </Helmet>
        <Header />
        <div>
          <div className="stripe-container">
            <div className="stripe-main-container">
              <div className="stripe-main">
                <div className="stripe-left-side">
                  <div className="subs-left-arrow-align">
                    <img
                      src={left_arrow}
                      onClick={() => this.goBack()}
                      className="subs-left-arrow"
                    />
                    <p className="subscription-top-text">Back to Pricing</p>
                  </div>
                  <p className="subs-left-title">
                    {" "}
                    <b>Methodology</b>
                  </p>
                  <p className="subs-left-subtext">
                    The SURFER Maps technology utilizes a series of nested
                    numerical grids in order to provide predictive data for the
                    7-day nearshore map forecasts. These forecasts refresh daily
                    at 3-hourly intervals.
                  </p>
                  <br />
                  <p className="subs-left-subtext">
                    We’re using US NOAA wave models to forecast waves at the
                    global, regional and higher resolution nearshore
                    transformation models. Additionally, we’re using some deeper
                    modeling developed by the US Army Corps of Engineers for
                    visual presentation.
                  </p>
                  <br />
                  <p className="subs-left-subtext">
                    These maps are reminiscent of the Swellwatch and Wetsand
                    maps. (The colors represent the relative wave height OR
                    period along the shoreline)
                  </p>
                  <br />
                  <p className="subs-left-subtext">Free:</p>
                  <br />
                  <p className="subs-left-subtext">
                    We've provided free 7-Day Wind Maps
                  </p>
                  <br />
                  <p className="subs-left-subtext">
                    We require a credit card to sign up for a free trial, but you won't be charged during the trial period.  We will will send a reminder email to you a few days before your subscription starts. If you're not happy with your subscription, you can cancel anytime.
                  </p>
                  <br />
                  <p className="subs-left-subtext">
                    We are a super small team trying to build forecast tools to help you surf more. We are a startup and sometimes there will be bugs in our tools, but we appreciate your feedback as it helps us build better features.
                  </p>
                  <br />
                  <p className="subs-left-subtext">

                    SURFERforecast is subscriber-supported and relies on surfers like you. Your support is greatly appreciated!
                                  </p> <br></br>
                  <p className="subs-left-subtext">
                    *Contact us if you run into any payment issues{" "}
                    <a href="mailto:contact@surfable.co">
                      (contact@surfable.co)
                    </a>
                  </p>
                  <br />
                  {/* <div className="subs-card">
                    <div className="subs-card-align">
                      <div className="subs-card-left-align">
                        <p className="subs-right-text">
                          {" "}
                          Lorem ipsum dolor sit amet
                        </p>
                        {this.state.planDetails.map((data, i) => (
                          <div className="plan-details-align">
                            <div className="plan-details">{data.planName}</div>
                            <div className="plan-details">${data.price}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div> */}
                  <div className="subs-card">
                    <div className="subs-card-align">
                      <div className="cards">
                        <div className="stripe-left-cards-wrap">
                          <div className="stripe-first-card">
                            <p className="stripe-plan-subtext">
                              Plan Selected{" "}
                            </p>
                            <p className="stripe-plan-title">
                              $ {this.props.match.params.price}
                              <span
                                style={{
                                  fontSize: "0.5em",
                                  fontFamily: "Source-REg",
                                  textAlign: "center",
                                  color: "",
                                }}
                              >
                                {" "}
                                / {this.props.match.params.plan}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stripe-right-side">
                  <div style={{ marginTop: "45px" }}>
                    <p className="subs-left-title"> Enter Payment Details</p>

                    <Elements
                      stripe={stripePromise}
                      plan={this.props.match.params.plan}
                    >
                      <CheckoutForm />
                    </Elements>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
