import logo from "./logo.svg";
import React from "react";
import "./App.css";
import SignIn from "./containers/auth/SignIn";
import SignUp from "./containers/auth/SignUp";
import home from "./containers/dashborad/home";
import ForgotPassword from "./containers/auth/ForgotPassword";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import SwellMap from "./containers/dashborad/maps/SwellMap";
import SubscriptionPage from "./containers/dashborad/SubscriptionPage";
import WindyPage from "./containers/dashborad/maps/windyPage";
import MySubscriptionPage from "./containers/dashborad/MySubscriptionPage";
import Stripe from "./containers/dashborad/stripe/Stripe";

class App extends React.Component {
  render() {
    return (
      <div className="body-wrapper">
        <Router>
          <Switch>
            <Route exact path="/login" component={SignIn} />
            <Route exact path="/register" component={SignUp} />
            <Route exact path="/" component={home} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route
              exact
              path="/swell_map/:location_name"
              component={SwellMap}
            />
            <Route
              exact
              path="/:location_name/subscription"
              component={SubscriptionPage}
            />
            <Route exact path="/subscription" component={SubscriptionPage} />
            <Route
              exact
              path="/my_subscriptions"
              component={MySubscriptionPage}
            />
            <Route exact path="/:location_name/windy" component={WindyPage} />
            <Route exact path="/stripe/:plan/:price" component={Stripe} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
