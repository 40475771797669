import React, { Component } from "react";
import "./SwellMap.css";
import legend_hs_ft from "../../../assets/images/legend_hs_ft.png";
import legend_tp_sec from "../../../assets/images/legend_tp_sec.png";
import close from "../../../assets/images/close.png";
import pause from "../../../assets/images/pause.png";
import play_button from "../../../assets/images/play-button.png";
import Header from "../Header";
import axios from "axios";
import L from "mapbox.js";
import $ from "jquery";
import moment from "moment-timezone";
import left_arrow from "../../../assets/images/left-arrow.svg";
import down_svg from "../../../assets/images/down.svg";
import play_svg from "../../../assets/images/play.svg";
import { BiWind } from "react-icons/bi";
import Slider from "rc-slider";
import { TimelineMax } from "gsap/all";
import "rc-slider/assets/index.css";
import { AiFillPlaySquare, AiFillPauseCircle, AiFillFastForward, AiFillFastBackward } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsPlayFill } from "react-icons/bs";
import { AiOutlinePause } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { Helmet } from "react-helmet";

const mapToken =
  "pk.eyJ1IjoiZGl0dG9wcyIsImEiOiJjazhuNDJlMWkwaThuM25uc2JoYmxtcW8wIn0.2063I606c-KsbhBfO1KV_A";
const S3_BUCKET = "https://swellmaps.s3-us-west-1.amazonaws.com";
let currentFolder = "";
let mapType = "height";
let mapObj;
let imageLayer;
let directionLayer;
let animationInterval;
let showDirectionLayer = true;
let mapLocation = "";
let resolution = {
  current: -1,
  list: ["512", "1024", "2048"],
  levels: [6, 8, 10],
  spec: {},
};
let frameCount = 0;
let firstzoom = true;
function pad(n, l) {
  var s = "" + n;
  while (s.length < l) s = "0" + s;
  return s;
}
function worldfile_extents(dx, dy, ox, oy, nx, ny) {
  var ex = ox + dx * (nx - 1);
  var ey = oy + dy * (ny - 1);
  return dy < 0.0 ? [ox, ey, ex, oy] : [ox, oy, ex, ey];
}
function generateImageUrl(index, type = "") {
  let layerType;
  type ? (layerType = type) : (layerType = mapType);
  return (
    S3_BUCKET +
    "/" +
    currentFolder +
    "/" +
    mapLocation +
    "/" +
    resolution.list[resolution.current] +
    "/" +
    layerType +
    "/" +
    mapLocation +
    "_" +
    layerType +
    "_" +
    resolution.list[resolution.current] +
    "_" +
    pad(index, 6) +
    ".png"
  );
}
$.fn.preload = function () {
  this.each(function () {
    setTimeout(() => {
      $("<img/>")[0].src = this;
    });
  });
};
function preloadImages() {
  let imageArray = [];
  let count = 0;
  while (count < resolution.spec.nt - 1) {
    imageArray.push(generateImageUrl(count));
    imageArray.push(generateImageUrl(count, "direction"));
    count++;
  }
  $(imageArray).preload();
}
toast.configure();
export default class SwellMap extends Component {
  dropdownLocationMenu = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      currentFolder: "",
      value1: true,
      currentFolder: "",
      mapType: "height",
      showDirectionLayer: true,
      mapLocation: "socal",
      resolution: {
        current: -1,
        list: ["512", "1024", "2048"],
        levels: [6, 8, 10],
        spec: {},
        frameCount: 0,

      },
      frameCount: 0,
      mapObj: "",
      imageLayer: "",
      directionLayer: "",
      animationInterval: "",
      lng: 5,
      lat: 34,
      zoom: 15,
      username: "",



      sliderMax: "",
      isSubscribed: "",
      token: "",
      isLoading: false,
      showSubscribe: false
    };
    this.tl = null;
    this.addImage = this.addImage.bind(this);
  }


  componentDidMount() {
    console.log(this.props.match.params.location_name);
    mapLocation = this.props.match.params.location_name;
    if (mapLocation) {
      this.getLatestConfig();
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    this.callApiIsUserSubscribed();
    this.setState({
      token: localStorage.getItem("token"),
    });
  }
  callApiIsUserSubscribed = () => {
    this.setState({
      isLoading: true,
    });

    const url =
      "https://maps-surfer-api.surfable.co/subscription/usersubscribe";
    const token = localStorage.getItem("token");
    console.log(token);

    const config = {
      headers: {
        Authorization: "Bearer" + " " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .get(url, config)
      .then((result) => {
        console.log(result.data);

        this.setState({
          isLoading: false,
          isSubscribed: result.data.isSubscribed,
        });

        // Do somthing
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          this.setState({
            isLoading: false,
            isSubscribed: error.response.data.isSubscribed,
          });

          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          this.setState({
            isLoading: false,
            isSubscribed: "",
          });
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          this.setState({
            isLoading: false,
            isSubscribed: "",
          });
        }
      });
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownLocationMenu.current &&
      !this.dropdownLocationMenu.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };
  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };

  toggleDirection = () => {

    this.setState({
      value1: !this.state.value1,

    });

    showDirectionLayer = !this.state.value1;
    if (!showDirectionLayer) {

      mapObj.removeLayer(directionLayer);

    } else {
      this.getConfig(resolution.current);

    }
  };

  changeMapType = (type) => {

    $(".legend-wrap").removeClass(mapType);
    mapType = type;
    $(".legend-wrap").addClass(mapType);
    $("#map-type .type").removeClass("active");
    $('.type[data-type="' + mapType + '"]').addClass("active");
    this.getConfig(resolution.current);


  };

  playControl(e) {
    let btn = $(e.target).closest('.play-btn');
    this.clearControl();
    if ($(btn).hasClass("active")) {
      $(btn).removeClass("active");
    } else {
      $('.play-btn').removeClass("active");
      $(btn).addClass("active");
      this.playAnimation();
      console.log(e);
    }
  }

  playAnimation = () => {
    if ((this.state.isSubscribed == false || this.state.token == null) && (this.state.frameCount >= 32)) {
      this.setState({
        showSubscribe: true,
        frameCount: 0
      });
      $('.play-btn').removeClass("active");
      return

    }
    this.addImage((this.state.frameCount + 1) % resolution.spec.nt);
    // $("#slider-wrap").slider({ value: frameCount });
    this.setState({
      sliderValue: this.state.frameCount,

    })
    animationInterval = setTimeout(this.playAnimation, 500);

  };

  clearControl() {
    clearTimeout(animationInterval);
    // $('.play-btn').removeClass("active");
  }

  fastForwardControl(e) {
    console.log("fast-forward");
    let btn = $(e.target).closest('.play-btn');
    this.clearControl();
    if ($(btn).hasClass("active")) {
      $(btn).removeClass("active");

    } else {
      $('.play-btn').removeClass("active");
      $(btn).addClass("active");
      this.fastForwardAnimation();
    }
  }

  fastForwardAnimation = () => {
    if ((this.state.isSubscribed == false || this.state.token == null) && (this.state.frameCount >= 32)) {
      this.setState({
        showSubscribe: true,
        frameCount: 0
      });
      $('.play-btn').removeClass("active");
      return

    }
    console.log(this.state.frameCount);
    this.addImage((this.state.frameCount + 3) % resolution.spec.nt);
    // $("#slider-wrap").slider({ value: frameCount });
    this.setState({
      sliderValue: this.state.frameCount,
    });
    animationInterval = setTimeout(this.fastForwardAnimation, 300);

  };

  reverseControl(e) {
    console.log("reverse");
    let btn = $(e.target).closest('.play-btn');
    this.clearControl();
    if ($(btn).hasClass("active")) {
      $(btn).removeClass("active");
    } else {
      $('.play-btn').removeClass("active");
      $(btn).addClass("active");
      this.reverseAnimation();
    }
  }

  reverseAnimation = () => {
    if ((this.state.isSubscribed == false || this.state.token == null) && (this.state.frameCount >= 32)) {
      this.setState({
        showSubscribe: true,
        frameCount: 0
      });
      $('.play-btn').removeClass("active");
      return;
    }
    this.addImage((this.state.frameCount - 3) % resolution.spec.nt);
    // $("#slider-wrap").slider({ value: frameCount });
    this.setState({
      sliderValue: this.state.frameCount,

    });
    animationInterval = setTimeout(this.reverseAnimation, 300);

  };


  onSliderChange(event) {
    console.log(event);
    if ((this.state.isSubscribed == false || this.state.token == null) && (event >= 32)) {
      return

    }

    this.addImage(event);

  }

  onZoomChange = () => {
    const zoom = mapObj.getZoom();
    let res = 0;
    if (zoom <= resolution.levels[0]) res = 0;
    if (zoom <= resolution.levels[1]) res = 1;
    if (zoom <= resolution.levels[2]) res = 2;
    if (res < 0) {
      // mapObj.removeLayer(imageLayer);
    } else {
      if (!mapObj.hasLayer(imageLayer)) mapObj.addLayer(imageLayer);
      this.getConfig(res);
      $(".play-btn").removeClass("active");
      clearTimeout(animationInterval);
    }
  };

  addImage = (frame) => {
    this.setState({
      frameCount: Math.max(0, Math.min(resolution.spec.nt - 1, frame)),
    });
    let frameCount = Math.max(0, Math.min(resolution.spec.nt - 1, frame));

    const url = generateImageUrl(frameCount);
    imageLayer.setUrl(url);

    if (showDirectionLayer) {
      const url = generateImageUrl(frameCount, "direction");
      directionLayer.setUrl(url);
    }
    let start = resolution.spec.cycle.split('T')[0];
    const dat = moment(start)
      .add(frameCount * resolution.spec.dt, "hours");
    // .tz("America/Los_Angeles");
    // $("#date").html(dat.format("DD MMM YYYY"));
    $("#date").html(dat.format("DD"));
    $("#day").html(dat.format("dddd"));
    $("#time").html(dat.format("hh A"));
  };


  hideSubscribePop() {
    this.setState({
      showSubscribe: false
    })
  }


  createSliderLegend() {
    let previous = "";
    $(".slider-legend-wrap").html("");
    $(".slider-legend-wrap-date-details").html("");
    $(".slider-legend-wrap-time").html("");

    let start = resolution.spec.cycle.split('T')[0];
    for (let count = 0; count < resolution.spec.nt; count++) {
      const dat = moment(start)
        .add(count * resolution.spec.dt, "hours");
      console.log(dat);
      if (previous != dat.format("DD/MM")) {
        $(".slider-legend-wrap").append(
          "<div class='legend-item date'><span class='slider-legend-date'>" +
          dat.format("MMM DD") +
          "</span><span class='slider-legend-day'>" +
          dat.format("ddd") +
          "</span></div>"
        );
        // $(".slider-legend-wrap").append(
        //   "<div class='legend-item date'></span><span class='slider-legend-day'>" +
        //     dat.format("ddd") +
        //     "</span></div>"
        // );

        $(".slider-legend-wrap-time").append(
          "<div class='legend-item date'><span class='slider-legend-day'>" +
          dat.format("ddd") +
          "</span></div>"
        );
        $(".slider-legend-wrap-date-details").append(
          "<div class='legend-item date'><span class='slider-legend-day'>" +
          dat.format("MMM DD") +
          "</span></div>"
        );
      } else {
        $(".slider-legend-wrap").append(
          "<div class='legend-item time'>" + dat.format("DD/MM hh A") + "</div>"
        );
      }

      previous = dat.format("DD/MM");
    }
    console.log("Here", $(".slider-legend-wrap").html());
  }

  getConfig = (res) => {
    res = Math.max(0, Math.min(resolution.list.length, res));
    // if (resolution.current !== res) {
    resolution.current = res;
    let url =
      S3_BUCKET +
      "/" +
      currentFolder +
      "/" +
      mapLocation +
      "/" +
      resolution.list[resolution.current] +
      "/" +
      mapType +
      "/" +
      mapLocation +
      "_" +
      mapType +
      "_" +
      resolution.list[resolution.current] +
      ".json";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .get(url, config)
      .then((result) => {
        console.log(result.data);
        this.initMap(result.data);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);

          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };
  initMap = (config) => {
    console.log("COOONFIG  ",config);
    resolution.spec = config;
    const extent = worldfile_extents(
      config.dx,
      config.dy,
      config.ox,
      config.oy,
      config.nx,
      config.ny
    );
    this.setState({
      sliderMax: config.nt,
    });
    // const SW = unmercator(extent[0], extent[1]);
    // const NE = unmercator(extent[2], extent[3]);
    const SW = [extent[1], extent[0]];
    const NE = [extent[3], extent[2]];

    if (!mapObj) {
      L.mapbox.accessToken = mapToken;
      var uri =
        "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=" +
        L.mapbox.accessToken;
      var opt = {
        tileSize: 512,
        maxZoom: 15,
        zoomOffset: -1,

      };

      mapObj = L.map("map-canvas", {
        attributionControl: false,
        zoomControl: false,

      }).addLayer(L.tileLayer(uri, opt));

      mapObj.setView([(SW[0] + NE[0]) / 2.0, (SW[1] + NE[1]) / 2.0], 8);
      mapObj.on("zoomend", this.onZoomChange);
      L.control.zoom({ position: "topleft" }).addTo(mapObj);
      setInterval(() => {
        if (mapLocation == "oahu" && firstzoom) {
          mapObj.setZoom(11);
          firstzoom = false;
        }
      });
    }

    if (imageLayer) mapObj.removeLayer(imageLayer);
    imageLayer = L.imageOverlay("", [SW, NE]).addTo(mapObj);
    if (directionLayer) mapObj.removeLayer(directionLayer);
    directionLayer = L.imageOverlay("", [SW, NE]).addTo(mapObj);
    this.addImage(0);
    preloadImages();

    // $("#slider-wrap").slider({
    //   min: 0,
    //   max: config.nt - 1,
    //   step: 1,
    //   slide: this.onSliderChange,
    //   value: frameCount,
    // });
    this.createSliderLegend();
    $("#zoom-level").html(resolution.current);
    $("#resolution").html(config.nx + " * " + config.ny);

  };
  getLatestConfig = () => {
    const url = S3_BUCKET + "/cycle_california.txt";
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .get(url, config)
      .then((result) => {
        console.log(result.data);
        currentFolder = result.data;
        this.getConfig(1);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);

          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };
  goToSubscriptionPage = () => {
    this.state.token == null
      ? toast.warning(
        "Please login to continue...",
        {
          autoClose: 4000,
        },
        this.props.history.push(`/login`)
      )
      : this.props.history.push(`/${mapLocation}/subscription`);
  };
  gotoWindyCom = () => {
    this.props.history.push(`/${mapLocation}/windy`);
  };
  goToLocation = (location) => {
    this.props.history.push(`/swell_map/${location}`);
    window.location.reload();
  };

  myChangeHandler = () => {
    if (this.setState.frameCount = "20") {
      window.location.reload();
    }
  }



  zoomIn() {
    this.mapObj.zoomIn();
  }

  renderLocation() {
    if (mapLocation == 'socal') {
      return <title>Southern California | SURFERforecast Maps</title>;


    } else if (mapLocation == 'nocal') {
      return <title>Northern California | SURFERforecast Maps</title>;


    }
    else if (mapLocation == 'oahu') {
      return <title>Oahu | SURFERforecast Maps</title>;


    }
    else if (mapLocation == 'hawaiian') {
      return <title>Hawaii| SURFERforecast Maps</title>;

    }
  }


  renderMeta() {
    if (mapLocation == 'socal') {

      return <meta name='description' content='7-Day Southern California Surf Wave Heights, Period and Swell Forecast'></meta>;



    } else if (mapLocation == 'nocal') {
      return <meta name='description' content='7-Day Northern California Surf Wave Heights, Period and Swell Forecast'></meta>;


    }
    else if (mapLocation == 'oahu') {
      return <meta name='description' content='7-Day Oahu Surf Wave Heights, Period and Swell Forecast'></meta>;


    }
    else if (mapLocation == 'hawaiian') {
      return <meta name='description' content='7-Day Hawaiian Island Surf Wave Heights, Period and Swell Forecast'></meta>;




    }
  }


  render() {

    console.log(this.state.isSubscribed ? 1 : 0);

    return (

      <>
        <Helmet>
          {this.renderLocation()}
          {this.renderMeta()}
        </Helmet>
        <Header />
        <div className="outer">
          {/* <div ref={el => this.mapContainer = el} className="mapContainer"/> */}
          <div className="map-wrap">
            <div id="map-canvas" ></div>
            <div className="swell-map-container">
              <div className="wrapper location-box-align">
                <div style={{ justifyContent: "flex-start" }}>
                  <div className="location-box-wrap">
                    <div
                      className="left-arrow-wrap"
                      onClick={() => this.props.history.push("/")}

                    >
                      <img src={left_arrow} onClick={() => this.props.history.push("/")} width="20px" />
                    </div>
                    <div className="loc_round">
                      {this.props.match.params.location_name == "nocal"
                        ? "NC"
                        : this.props.match.params.location_name == "socal"
                          ? "SC"
                          : this.props.match.params.location_name == "oahu"
                            ? "OA"
                            : "HA"}
                    </div>
                    <p className="loc_name">
                      {this.props.match.params.location_name == "nocal"
                        ? "Northern California"
                        : this.props.match.params.location_name == "socal"
                          ? "Southern California"
                          : this.props.match.params.location_name == "oahu"
                            ? "Oahu"
                            : "Hawaii"}
                    </p>
                    <div className="swellMapdropdownMenu" ref={this.dropDownLocationMenu} >
                      <div className="down-box" onClick={this.handleButtonClick}>

                        <img src={down_svg} width="11px" />

                      </div>
                      {this.state.open && (
                        <div className="dropdown-items">
                          <div>
                            <ul>
                              {this.props.match.params.location_name ==
                                "nocal" ? (
                                  <>
                                    <li
                                      className="items"
                                      onClick={() => this.goToLocation("socal")}
                                    >
                                      Southern California
                                  </li>
                                    <li
                                      className="items"
                                      onClick={() => this.goToLocation("oahu")}

                                    >
                                      Oahu
                                  </li>
                                    <li
                                      className="items"
                                      onClick={() =>
                                        this.goToLocation("hawaiian")
                                      }
                                    >
                                      Hawaii
                                  </li>
                                  </>
                                ) : null}
                              {this.props.match.params.location_name ==
                                "socal" ? (
                                  <>
                                    <li
                                      className="items"
                                      onClick={() => this.goToLocation("nocal")}
                                    >
                                      Northern California
                                  </li>
                                    <li
                                      onClick={() => this.goToLocation("oahu")}
                                      className="items"
                                    >
                                      Oahu
                                  </li>
                                    <li
                                      className="items"
                                      onClick={() =>
                                        this.goToLocation("hawaiian")
                                      }
                                    >
                                      Hawaii
                                  </li>
                                  </>
                                ) : null}
                              {this.props.match.params.location_name ==
                                "oahu" ? (
                                  <>
                                    <li
                                      className="items"
                                      onClick={() => this.goToLocation("nocal")}
                                    >
                                      Northern California
                                  </li>
                                    <li
                                      className="items"
                                      onClick={() => this.goToLocation("socal")}
                                    >
                                      Southern California
                                  </li>
                                    <li
                                      className="items"
                                      onClick={() =>
                                        this.goToLocation("hawaiian")
                                      }
                                    >
                                      Hawaii
                                  </li>
                                  </>
                                ) : null}
                              {this.props.match.params.location_name ==
                                "hawaiian" ? (
                                  <>
                                    <li
                                      className="items"
                                      onClick={() => this.goToLocation("nocal")}
                                    >
                                      Northern California
                                  </li>
                                    <li
                                      className="items"
                                      onClick={() => this.goToLocation("socal")}
                                    >
                                      Southern California
                                  </li>
                                    <li
                                      onClick={() => this.goToLocation("oahu")}
                                      className="items"
                                    >
                                      Oahu
                                  </li>
                                  </>
                                ) : null}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="legend-wrap height">
                  <img style={{ width: "100%" }} src={legend_hs_ft} alt="" />
                  <img style={{ width: "100%" }} src={legend_tp_sec} alt="" />
                </div>
              </div>
              <div style={{}}>
                <div
                  className="wind-btn-wrap"
                >
                  <button
                    className="wind-Button"
                    onClick={() => this.gotoWindyCom()}
                  >
                    <BiWind className="wind-icon" color="#F76C6C" size="20px" />
                    Wind
                  </button>

                  {/* <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <button className="list_btn">List</button>
                    <button className="map_btn">Map</button>
                  </div> */}
                </div>
              </div>

              <div className="wrapper" >
                <div className="option-wrap">
                  <div className="map-type-wrap" id="map-type">
                    <div
                      className="type active"
                      data-type="height"
                      onClick={() => this.changeMapType("height")}

                    >
                      Height
                    </div>
                    <div
                      className="type "
                      data-type="period"
                      onClick={() => this.changeMapType("period")}
                    >
                      Period
                    </div>
                  </div>
                  <div className="direction-title-wrap">
                    <p className="title-wrap-date">
                      <span id="date">05</span>
                      {/* <span id="day">Sunday</span> */}
                    </p>

                    <p className="title-wrap-time" id="time">
                      05 am
                    </p>

                    <div className="direction-toggle">
                      <input
                        className="styled-checkbox"
                        id="direction-toggle"
                        type="checkbox"
                        onChange={(e) => this.toggleDirection()}
                        checked={this.state.value1}

                      />
                      <label htmlFor="direction-toggle">Direction</label>
                    </div>
                  </div>
                </div>
                <div className="info-box-wrap">
                  <div className="title-wrap">
                    {/* <p className="title-wrap-date">
                      <span id="date">05 May 2020</span>,{" "}
                      <span id="day">Sunday</span>
                    </p>

                    <p className="title-wrap-time" id="time">
                      05 AM
                    </p> */}
                    <div
                      className="play-btn"
                      onClick={(e) => this.reverseControl(e)}
                    >

                      <AiFillFastBackward
                        className="play_btn"
                        size="45px"
                        color="#F76C6C"
                      />

                      <AiOutlinePause
                        className="pause_btn"
                        size="40px"
                        color="#F76C6C"
                      />
                    </div>
                    <div
                      className="play-btn"
                      onClick={(e) => this.playControl(e)}
                    >

                      <BsPlayFill
                        className="play_btn"
                        size="45px"
                        color="#F76C6C"
                      />

                      <AiOutlinePause
                        className="pause_btn"
                        size="40px"
                        color="#F76C6C"
                      />
                    </div>
                    <div
                      className="play-btn"
                      onClick={(e) => this.fastForwardControl(e)}
                    >

                      <AiFillFastForward
                        className="play_btn"
                        size="45px"
                        color="#F76C6C"
                      />

                      <AiOutlinePause
                        className="pause_btn"
                        size="40px"
                        color="#F76C6C"
                      />
                    </div>

                  </div>

                  <div className="slider-container">
                    {/* <div
                      className="play-btn"
                      onClick={() => this.playControl()}
                    >
                      <AiFillPlaySquare
                        className="play_btn"
                        size="45px"
                        color="#F76C6C"
                      />
                      <AiFillPauseCircle
                        className="pause_btn"
                        size="50px"
                        color="#F76C6C"
                      />
                    </div> */}

                    <div className="slider-wrap ui-slider" id="slider-wrap">
                      <div className="slider-legend-wrap"></div>

                      <div className="play-pause-mobile">
                        <div className="slider-legend-wrap-date-details"></div>
                      </div>

                      <div className="play-pause-web">

                        <Slider
                          min={0}
                          max={this.state.sliderMax + 7}
                          value={this.state.frameCount}
                          onChange={(e) => this.onSliderChange(e)}

                          trackStyle={{
                            backgroundColor: "#FF654969",
                            height: "5.5px",
                            borderRadius: "0px",
                          }}
                          railStyle={{
                            backgroundColor: "#FF654969",
                            height: "5.5px",
                            borderRadius: "0px",
                          }}
                          step={1}
                          handleStyle={{
                            height: "16px",
                            width: "5.5px",
                            marginLeft: "0px",
                            marginTop: "-10.5px",
                            backgroundColor: "#F76C6C",
                            border: "none",
                            borderRadius: "1px",
                          }}
                          dotStyle={{
                            width: 30,
                            border: "none",
                            borderRadius: 0,
                            height: 1,
                            backgroundColor: "blue",
                          }}
                          activeDotStyle={{
                            backgroundColor: "red",
                          }}
                        // onBeforeChange={() => this.tl.pause()}
                        // onAfterChange={this.onAfterChange}
                        />

                      </div>



                      <div className="play-pause-mobile">
                        <Slider
                          min={0}
                          max={this.state.sliderMax + 7}
                          value={this.state.frameCount}
                          onChange={(e) => this.onSliderChange(e)}
                          trackStyle={{
                            backgroundColor: "#FF654969",
                            height: "1px",
                            borderRadius: "0px",
                          }}
                          railStyle={{
                            backgroundColor: "#FF654969",
                            height: "1px",
                            borderRadius: "0px",
                          }}
                          step={1}
                          handleStyle={{
                            // height: "13px",
                            // width: "4px",
                            marginLeft: "0px",
                            marginTop: "-7px",
                            backgroundColor: "#F76C6C",
                            border: "none",
                            // borderRadius: "1px",
                          }}
                          dotStyle={{
                            width: 30,
                            border: "none",
                            borderRadius: 0,
                            height: 1,
                            backgroundColor: "blue",
                          }}
                          activeDotStyle={{
                            backgroundColor: "red",
                          }}
                        // onBeforeChange={() => this.tl.pause()}
                        // onAfterChange={this.onAfterChange}
                        />
                      </div>
                      <div className="play-pause-mobile">
                        <div className="slider-legend-wrap-time"></div>
                      </div>
                    </div>
                    {this.state.isSubscribed == "false" ||
                      !this.state.isSubscribed ||
                      this.state.token == null ?
                      (
                        <div className="subcribe-box-align" >
                          <div className="subscribe-box-wrap">
                            <div>

                              <p className="subscribe-box-subtext">
                                Subscribe to view 10-day forecast
                            </p>
                            </div>
                            <button
                              className="subscribe_btn"
                              // onClick={() => this.goToSubscriptionPage()}
                              onClick={() => this.props.history.push(`/subscription`)}
                            >
                              Free Trial
                          </button>
                          </div>
                        </div>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isLoading ? (
            <div className="sweet-loading">
              <ClipLoader size={50} color={"#ffffff"} loading={true} />
            </div>
          ) : null}
          {this.state.showSubscribe ? <div className="popup-container" onClick={() => this.hideSubscribePop()}>
            <div className="popup-outer" onClick={(e) => e.stopPropagation()}>
              <img src={close} className="close-icon" onClick={() => this.hideSubscribePop()} />
              <h2>Subscribe Now!</h2>
              <p>10 day surf wave height & period maps for Southern California, Northern California and Hawaii</p>
              <button class="subscribe-btn" onClick={() => this.props.history.push(`/subscription`)}>Subscribe</button>
            </div>
          </div> : null}
        </div>
      </>
    );
  }
}
