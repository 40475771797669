import React, { Component } from "react";
import Header from "../Header";
// import "./SwellMap.css";
import left_arrow from "../../../assets/images/left-arrow.svg";
import "./windyPage.css";
import down_svg from "../../../assets/images/down.svg";
import fav_svg from '../../../assets/images/fav.svg';
import {Helmet} from "react-helmet";

export default class windyPage extends Component {
  dropDownLocationMenu = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      location: "",
    };
  }
  componentDidMount() {
    this.setState({
      location: this.props.match.params.location_name,
    });
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.dropDownLocationMenu.current &&
      !this.dropDownLocationMenu.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };
  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };
  gotoSwellMap = () => {
    if (this.state.location == "northAmerica") {
      this.props.history.push(`/`);
    } else {
      this.props.history.push(`/swell_map/${this.state.location}`);
      window.location.reload();
    }
  };
  goToLocation = (location) => {
    this.props.history.push(`/${location}/windy`);
    window.location.reload();
  };

  renderLocation(){
    if(this.state.location=='socal') {
    return   <title>Southern California | SURFERforecast Maps</title>;   
    
    
    } else if(this.state.location=='nocal'){
      return <title>Northern California | SURFERforecast Maps</title>;   
     
    
    }
    else if(this.state.location=='oahu'){
      return  <title>Oahu | SURFERforecast Maps</title>;
     
    
    }
    else if(this.state.location=='hawaiian'){
      return <title>Hawaii| SURFERforecast Maps</title>;   
      
    }
    }
    
    
    renderMeta(){
      if(this.state.location=='socal') {
        
        return   <meta name='description' content='7-Day Southern California Ocean, Surf and Beach Wind Forecast'></meta>;
       
        
        
        } else if(this.state.location=='nocal'){
          return   <meta name='description' content='7-Day Northern California Ocean, Surf and Beach Wind Forecast'></meta>;
         
        
        }
        else if(this.state.location=='oahu'){
          return   <meta name='description' content='7-Day Oahu Ocean, Surf and Beach Wind Forecast'></meta>;
         
        
        }
        else if(this.state.location=='hawaiian'){
          return   <meta name='description' content='7-Day Hawaiian Island Ocean, Surf and Beach Wind Forecast'></meta>;
         
        
    
    
    }
    }
    

  render() {
    return (
      <div className="windy-container">
        <Helmet>{this.renderLocation()}</Helmet>
        <Header />
        <div className="windy-height-align">
          {/* */}
          <div className="windy-wrapper">
            <div className="windy-page-container">
              <div className="windy-location">
                <div className="windy-location-box-wrap">
                  <div
                    className="windy-left-arrow-wrap"
                    onClick={() => this.gotoSwellMap()}
                  >
                    <img src={left_arrow} className="left-arrow-img" />
                  </div>
                  <div className="windy-loc_round">
                    {this.props.match.params.location_name == "nocal"
                      ? "NC"
                      : this.props.match.params.location_name == "socal"
                        ? "SC"
                        : this.props.match.params.location_name == "oahu"
                          ? "OA"
                          : this.props.match.params.location_name == "hawaiian"
                            ? "HA"
                            : "NA"}
                  </div>
                  <p className="windy-loc_name">
                    {this.props.match.params.location_name == "nocal"
                      ? "Northern California"
                      : this.props.match.params.location_name == "socal"
                        ? "Southern California"
                        : this.props.match.params.location_name == "oahu"
                          ? "Oahu"
                          : this.props.match.params.location_name == "hawaiian"
                            ? "Hawaii"
                            : "North America"}
                  </p>
                  <div className="dropdownMenu" ref={this.dropDownLocationMenu}>
                    <div className="down-box" onClick={this.handleButtonClick}>
                      <img src={down_svg} width="11px" />
                    </div>
                    {this.state.open && (
                      <div className="windy-dropdown-items">
                        <div>
                          <ul>
                            {this.props.match.params.location_name ==
                              "nocal" ? (
                                <>
                                  <li onClick={() => this.goToLocation("socal")} className="items">
                                    Southern California
                                </li>
                                  <li onClick={() => this.goToLocation("oahu")} className="items">
                                    Oahu
                                </li>
                                  <li
                                    onClick={() => this.goToLocation("hawaiian")} className="items"
                                  >
                                    Hawaii
                                </li>
                                </>
                              ) : null}
                            {this.props.match.params.location_name ==
                              "socal" ? (
                                <>
                                  <li onClick={() => this.goToLocation("nocal")} className="items">
                                    Northern California
                                </li>
                                  <li onClick={() => this.goToLocation("oahu")} className="items">
                                    Oahu
                                </li>
                                  <li
                                    onClick={() => this.goToLocation("hawaiian")} className="items"
                                  >
                                    Hawaii
                                </li>
                                </>
                              ) : null}
                            {this.props.match.params.location_name == "oahu" ? (
                              <>
                                <li onClick={() => this.goToLocation("nocal")} className="items">
                                  Northern California
                                </li>
                                <li onClick={() => this.goToLocation("socal")} className="items">
                                  Southern California
                                </li>
                                <li
                                  onClick={() => this.goToLocation("hawaiian")} className="items"
                                >
                                  Hawaii
                                </li>
                              </>
                            ) : null}
                            {this.props.match.params.location_name ==
                              "hawaiian" ? (
                                <>
                                  <li onClick={() => this.goToLocation("nocal")} className="items">
                                    Northern California
                                </li>
                                  <li onClick={() => this.goToLocation("socal")} className="items">
                                    Southern California
                                </li>
                                  <li onClick={() => this.goToLocation("oahu")} className="items">
                                    Oahu
                                </li>
                                </>
                              ) : null}
                            {this.props.match.params.location_name ==
                              "northAmerica" ? (
                                <>
                                  <li className="items"
                                    onClick={() => this.goToLocation("nocal")}>
                                    Northern California
                                </li>
                                  <li className="items" onClick={() => this.goToLocation("socal")}>
                                    Southern California
                                </li>
                                  <li className="items" onClick={() => this.goToLocation("oahu")}>
                                    Oahu
                                </li>
                                  <li className="items"
                                    onClick={() => this.goToLocation("hawaiian")}
                                  >
                                    Hawaii
                                </li>
                                </>
                              ) : null}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div onClick={() => this.gotoSwellMap()}>
                  <div className="surfer-Button">
                    <img src={fav_svg} className="fav-icon" />
                    <span className="surfer-text"> Swell</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.location == "hawaiian" ? (
            <iframe
              width="100%"
              height="100%"
              src="https://embed.windy.com/embed2.html?lat=20.875&lon=-157.974&detailLat=20.875&detailLon=-157.974&width=650&height=450&zoom=8&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
              frameborder="0"
            ></iframe>
          ) : this.state.location == "oahu" ? (
            <iframe
              width="100%"
              height="100%"
              src="https://embed.windy.com/embed2.html?lat=20.875&lon=-157.974&detailLat=20.875&detailLon=-157.974&width=650&height=450&zoom=9&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
              frameborder="0"
            ></iframe>
          ) : this.state.location == "socal" ? (
            <iframe
              width="100%"
              height="100%"
              src="https://embed.windy.com/embed2.html?lat=34.05&lon=-118.24&detailLat=40.301&detailLon=-124.173&width=650&height=450&zoom=8&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
              frameborder="0"
            ></iframe>
          ) : this.state.location == "nocal" ? (
            <iframe
              width="100%"
              height="100%"
              src="https://embed.windy.com/embed2.html?lat=37.773&lon=-122.431&detailLat=32.185&detailLon=-117.213&width=650&height=450&zoom=8&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
              frameborder="0"
            ></iframe>
          ) : (
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://embed.windy.com/embed2.html?lat=30.865&lon=-110.566&detailLat=30.865&detailLon=-110.566&width=650&height=450&zoom=4&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
                      frameborder="0"
                    ></iframe>
                  )}
          {/* </div> */}
        </div>
      </div>
    );
  }
}
