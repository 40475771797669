import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import qs from "qs";
import axios from "axios";
import CardSection from "./CardSection";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

toast.configure();
class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      planName: "",
      withTrial: "",
      isLoading: false,
      price: "",
    };
  }
  componentDidMount() {
    this.setState({
      planName: localStorage.getItem("plan"),
      price: localStorage.getItem("price"),
    }); 
  }

  handleSubmit = async (event, history) => {
    event.preventDefault();

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    const result = await stripe.createToken(card);
    if (result.error) {
      console.log(result.error.message);
      toast.error(`${result.error.message}`);
    } else {
      console.log(result.token);
      console.log(result.token.id);
      if (result.token.id) {
        this.callPaymentApi(result.token.id, history);
      }
    }
  };
  callPaymentApi = (tokenId, history) => {
    this.setState({
      isLoading: true,
    });

    const url = "https://maps-surfer-api.surfable.co/subscription/payment";
    const requestBody = {
      planName:
        localStorage.getItem("price") == 0 ? "month" : this.state.planName,
      tokenId: tokenId,
      withTrial: localStorage.getItem("price") == 0 ? 1 : 0,
      name: this.state.name,
    };

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };
    axios
      .post(url, qs.stringify(requestBody), config)
      .then((res) => {
        console.log(res.data);
        // this.setState({
        //   isLoading: false,
        // });
        setTimeout(() => this.callPaymentResultApi(history), 10000);

        // toast.success(`${res.data.message}`);

        // Do somthing
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          toast.error(`${error.response.data.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          toast.error(`OOPS something went wrong!`);
          // The request was made but no response was received
          console.log(error.request);
        } else {
          toast.error(`OOPS something went wrong!`);
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }

        this.setState({
          isLoading: false,
        });
      });
  };
  callPaymentResultApi = (history) => {
    this.setState({
      isLoading: true,
    });

    const url =
      "https://maps-surfer-api.surfable.co/subscription/paymentresult";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        console.log(res.data);

        this.setState({
          isLoading: false,
        });
        toast.success(`${res.data.Message}`);
        history.push("/");
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          toast.error(`${error.response.data}`);
          // toast.error(`${error.response.data.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          toast.error("OOPS Something went wrong");
          // toast.error(`OOPS something went wrong!`);
          // The request was made but no response was received
          console.log(error.request);
        } else {
          toast.error("OOPS Something went wrong");
          // toast.error(`OOPS something went wrong!`);
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }

        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <Route
        render={({ history }) => (
          <div className="card-width">
            <form onSubmit={(e) => this.handleSubmit(e, history)}>
              <label>Card Details</label>
              <br />
              <br />

              <input
                name="name"
                type="text"
                placeholder="Name on card"
                required
                onChange={(e) => {
                  this.setState({
                    name: e.target.value,
                  });
                }}
                className="StripeElement"
              />
            

              <CardSection />
            
              <input
                name="code"
                type="text"
                placeholder="Promo Code"
                
                onChange={(e) => {
                  this.setState({
                    code: e.target.value,
                  });
                }}
                className="StripeElement"
              />
              <button disabled={!this.props.stripe} className="btn-pay">
                CHECKOUT
              </button>
            </form>
            {this.state.isLoading ? (
              <div className="sweet-loading">
                <ClipLoader size={50} color={"#ffffff"} loading={true} />
              </div>
            ) : null}
          </div>
        )}
      />
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
