import React, { Component } from "react";
import Header from "./Header";
import "./home.css";
import { BiWind } from "react-icons/bi";
import "./Header.css";
import map from "../../assets/images/map.png";
import {Helmet} from "react-helmet";

export default class home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: ''
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");
    this.setState({
      token: token
    });
  }
  goToSwellMap = (location) => {
    console.log(location);
    // this.props.history.push("/swell_map")
    this.props.history.push(`/swell_map/${location}`);
    window.location.reload();
  };

  render() {
    return (
      <div>
       <Header/>
        <Helmet>
          <title>Home | SURFERforecast Maps</title>
          <meta name="description" content=" Surf, Wind and Wave Height Forecast Maps. Official forecasts from SURFER Magazine"></meta> 
        </Helmet>
        
        <div className="home-container">
          {/* <div className="map-img"></div> */}
          <div className="container-wrap">
            <div className="wind-button-align">
              {!this.state.token ? <button
                className="wind-button trial-btn"
                onClick={() => this.props.history.push(`/subscription`)}
              >
                Free Trial
              </button> : null}
              <button
                className="wind-button"
                onClick={() => this.props.history.push(`/socal/windy`)}
              >
                <BiWind className="wind-icon" color="#F76C6C" size="20px" />
                Wind
              </button>
            </div>


            <div className="sea-card-align">
              <p className="text">Regions</p>
              <div className="sea-img-card-wrap">
                <div
                  className="sea-img-wrap fourth-img"
                  onClick={() => this.goToSwellMap("socal")}
                >
                  <div className="sea-overlay"></div>
                  <div className="sea-img">
                    <div className="sea-circle">SC</div>
                    <p>Southern California</p>
                  </div>
                </div>
                <div
                  className="sea-img-wrap first-img"
                  onClick={() => this.goToSwellMap("nocal")}
                >
                  <div className="sea-overlay"></div>
                  <div className="sea-img">
                    <div className="sea-circle">NC</div>
                    <p>Northern California</p>
                  </div>
                </div>



                <div
                  className="sea-img-wrap third-img"
                  onClick={() => this.goToSwellMap("oahu")}
                >
                  <div className="sea-overlay"></div>
                  <div className="sea-img">
                    <div className="sea-circle">OA</div>
                    <p>Oahu</p>
                  </div>
                </div>

                <div
                  className="sea-img-wrap second-img"
                  onClick={() => this.goToSwellMap("hawaiian")}
                >
                  <div className="sea-overlay"></div>
                  <div className="sea-img">
                    <div className="sea-circle">HA</div>
                    <p>Hawaii</p>
                  </div>
                </div>
                <div className="map-img-align">
                  <img src={map} style={{ width: "100%" }} />
                </div>
                <div className="home-text-card">
                  <div className="home-text-card-align">
                    <p className="home-text-card-title">
                      10 day surf wave height & period maps for Southern
                      California, Northern California and Hawaii
                    </p>
                    <p className="home-text-card-subtext-title">Free: </p>

                    <p className="home-text-card-subtext">
                    Free 3 Day swell forecasts for all members
                    </p>
                    <p className="home-text-card-subtext">
                    Free 7 Day wind forecasts for all members 
                    </p>
                    <p style={{'marginTop':'10px'}} className="home-text-card-subtext-title">Premium: </p>

                    <p className="home-text-card-subtext">
                    30 Day Free Trial for 10 day forecasts
                    </p>

                    <p className="home-text-card-subtext">
                    Monthly Membership unlocks access to all 10 day swell forecasts
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="map-dot-wrap">
              <div className="map-dots">
                <span className="dot" onClick={() => this.goToSwellMap("nocal")}></span>
                <span className="dot1" onClick={() => this.goToSwellMap("socal")}></span>
                <span className="dot2" onClick={() => this.goToSwellMap("oahu")}></span>
                <span className="dot3" onClick={() => this.goToSwellMap("hawaiian")}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
