import React, { Component } from "react";
import Header from "./Header";
import "./MySubscriptionPage.css";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";
import qs from "qs";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export default class MySubscriptionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      planDatas: "",
      isLoading: false,
    };
  }
  componentDidMount() {
    this.callUserPlanApi();
    this.getSubscriptionPage();
  }

  callUserPlanApi = () => {
    this.setState({
      isLoading: true,
    });
    const url = "https://maps-surfer-api.surfable.co/subscription/userplan";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };
    axios
      .get(url, config)
      .then((result) => {
        console.log(result.data.dataSubscription);
        this.setState({
          planDatas: result.data.dataSubscription,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          this.setState({
            planDatas: "",
            isLoading: false,
          });
          // toast.error(`${error.response.data.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          this.setState({
            planDatas: "",
            isLoading: false,
          });
          // The request was made but no response was received
          console.log(error.request);
          // toast.error("Oops Something Went Wrong");
        } else {
          this.setState({
            planDatas: "",
            isLoading: false,
          });
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // toast.error("Oops Something Went Wrong");
        }

        this.setState({
          isLoading: false,
          planDatas: "",
        });
      });
  };

  renderTableData() {
    const { planDatas } = this.state;

    return (
      <tr>
        <td className="body-text">{planDatas.planName}</td>
        <td className="body-text">
          {planDatas.status == 1 ? "Active" : "Inactive"}
        </td>
        <td className="body-text">
          {moment(planDatas.renewalDate).format("DD MMM YYYY")}
        </td>
        <td className="body-text">${planDatas.initialAmount}</td>

        <td
          className="cancel"
          onClick={(e) =>
            window.confirm("Are you sure want to unsubscribe?") &&
            this.callCancelSubscriptionApi()
          }
        >
          Cancel
        </td>
      </tr>
    );
  }
  callCancelSubscriptionApi = () => {
    this.setState({
      isLoading: true,
    });

    const url =
      "https://maps-surfer-api.surfable.co/subscription/cancelsubscription";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };
    console.log(2332);
    console.log(config);

    axios
      .post(url, {}, config)
      .then((res) => {
        console.log(res.data);
        this.setState({
          isLoading: false,
        });
        this.callUserPlanApi();
        // toast.success(`${res.data.message}`);

        // Do somthing
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          this.setState({
            planDatas: "",
          });
          // toast.error(`${error.response.data.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          this.setState({
            planDatas: "",
          });
          // toast.error(`OOPS something went wrong!`);
          // The request was made but no response was received
          console.log(error.request);
        } else {
          this.setState({
            planDatas: "",
          });
          // toast.error(`OOPS something went wrong!`);
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }

        this.setState({
          isLoading: false,
          planDatas: "",
        });
      });
  };

  getSubscriptionPage = () => {
    this.setState({
      isLoading: true,
    });

    const url =
      "https://maps-surfer-api.surfable.co/subscription/customerportel";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };

    axios
      .post(url, {}, config)
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data.session;
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {

        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <div>
        <Header />
        <div className="my-subscription-header">
          <div className="my-subs-heading-align">
            <div className="back-to-text" style={{ marginRight: "20px" }}>
              {" "}
              <BsArrowLeft
                onClick={() => this.props.history.push("/")}
                // size="30px"
                style={{
                  position: "relative",
                  fontSize: "30px",
                  top: "10px",
                  cursor: "pointer",
                }}
              />
              Back to Home
            </div>
            <p className="my-subs-heading"> Subscriptions</p>
          </div>
        </div>
        <div className="card-align">
          <div className="my-subscription-card">
            <div
              className="card-header"
              style={{
                borderBottom:
                  this.state.planDatas.length == 0 ? "1px solid #ddd" : null,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FaFileInvoiceDollar
                  color=" #f76c6c"
                  className="subscriptions-icon"
                />
                <div className="card-title"> Subscriptions</div>
              </div>
            </div>

            {this.state.planDatas !== "" ? (
              <table id="students">
                <tbody>
                  <tr>
                    <th className="title">Subscription Plan Name</th>
                    <th className="title">Status</th>
                    <th className="title">Renewal Date</th>
                    <th className="title">Initial Amount</th>
                    {/* <th className="title">Times Billed</th> */}
                    <th className="title">Actions</th>
                  </tr>

                  {this.renderTableData()}
                </tbody>
              </table>
            ) : (
                <p style={{ textAlign: "center" }}>No Records Found!!!</p>
              )}
          </div>
        </div>
        {this.state.isLoading ? (
          <div className="sweet-loading">
            <ClipLoader size={50} color={"#ffffff"} loading={true} />
          </div>
        ) : null}
      </div>
    );
  }
}
