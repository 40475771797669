import React, { Component } from "react";
import "./SignIn.css";
import { BsArrowLeft } from "react-icons/bs";
import { ReactComponent as EmailSvg } from "../../assets/images/email.svg";
import logo from "../../assets/images/logo.png";
import qs from "qs";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../dashborad/Header";

toast.configure();
export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validEmail: false,
      validPassword: false,
      emailErrorMsg: "",
      email: "",
      emailError: false,
      errors: {},
      errorMsgText: "",
      errorMsg: false,
      isLoading: false,
      focus: false,
    };
    this._onFocus = this._onFocus.bind(this);
  }

  _onFocus() {
    if (!this.state.focus) {
      this.setState({
        focus: true,
      });
    }
  }

  validateEmail = (e) => {
    let emailRegExp = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/;
    const email = e.target.value;

    if (email == "") {
      this.setState({
        validEmail: true,
        emailErrorMsg: "Email is required",
      });
    } else if (!email.match(emailRegExp)) {
      this.setState({
        validEmail: true,
        emailErrorMsg: "Please enter the valid email",
      });
    } else {
      this.setState({
        validEmail: false,
        emailErrorMsg: "",
        focus: false,
      });
    }
  };

  componentDidMount() {
    // this.refs.email.focus();
  }

  handleValidation() {
    const { email, password } = this.state;
    let errors = {};
    let formIsValid = true;
    let emailRegExp = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/;

    //email
    if (this.state.email == "") {
      formIsValid = false;
      errors["email"] = "Email is required";
      this.setState({
        emailError: true,
      });
    } else if (typeof email !== "undefined") {
      if (!email.match(emailRegExp)) {
        formIsValid = false;
        errors["email"] = "Invalid email address";
        this.setState({
          emailError: true,
        });
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  onSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      this.setState({
        isLoading: true,
      });

      const url = "https://maps-surfer-api.surfable.co/user/forgotpassword";
      const requestBody = {
        email: this.state.email,
      };

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios
        .post(url, qs.stringify(requestBody), config)
        .then((result) => {
          console.log(result);
          this.setState({
            isLoading: false,
          });
          toast.success(
            "Your new password has been sent to your email address",
            {
              autoClose: 5000,
            }
          );
          setTimeout(() => {
            this.props.history.push("/login");
          }, 5000);
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            toast.error(`${error.response.data.data}`);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            toast.error("Oops Something Went Wrong");
          } else {
            toast.error("Oops Something Went Wrong");
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }

          this.setState({
            isLoading: false,
          });
        });
    }
  }

  render() {
    const { errors, validEmail } = this.state;

    return (
      <>
        <Header />
        <div className="imageBackground">
          {/* <div className="overlay"></div> */}
          <div className="container">
            <div className="main">
              <div className="left-side">
                <img
                  src={logo}
                  className={"logoImg"}
                  style={{ marginBottom: "4px" }}
                />

                <p className="left-side-text">
                  <b className="lgntxt">
                    7 day surf wave height & period maps for Southern
                    California, Northern California and Hawaii
                  </b>
                </p>
                <p className="left-side-text, lgntxt">
                  Create an account to access the SURFER Swellmaps
                </p>
              </div>

              <div className="right-side">
                <p className="login-text"> Forgot Your Password? </p>
                <p className="sub-text">
                 
                </p>
                {/* <div style={{ maxWidth: "600px" }}> */}

                <div
                  className="input-container"
                  style={{
                    marginBottom:
                      this.state.validEmail || this.state.emailError ? 0 : "5%",
                    border:
                      this.state.validEmail || this.state.emailError
                        ? "1px solid red"
                        : this.state.focus
                        ? "3px solid #2680EB0F"
                        : "1px solid #CDDEF3",
                  }}
                >
                  <EmailSvg className="icons" />
                  <input
                    type="email"
                    placeholder="Email Address"
                    ref="email"
                    onBlur={this.validateEmail}
                    onFocus={this._onFocus}
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                        emailError: false,
                        errorMsg: false,
                        validEmail: false,
                        emailErrorMsg: "",
                      })
                    }
                    value={this.state.email}
                  />
                </div>
                {validEmail || this.state.emailError ? (
                  <p style={{ marginBottom: "5%" }} className="error-Text">
                    {this.state.emailErrorMsg || errors["email"]}
                  </p>
                ) : (
                  ""
                )}

                <button
                  style={{ marginTop: "6%" }}
                  type="submit"
                  className="btn"
                  onClick={this.onSubmit.bind(this)}
                >
                  Reset Password
                </button>

                <p
                  onClick={() => {
                    this.props.history.push("/login");
                  }}
                  style={{
                    color: "#f76c6c",
                    cursor: "pointer",
                    fontSize: "1em",
                    fontFamily: "Source-SemiBold",
                    margin: 0,
                    textAlign: "center",
                  }}
                >
                  <BsArrowLeft
                    size="30px"
                    color="#f76c6c"
                    style={{ position: "relative", top: "10px" }}
                  />{" "}
                  Back to Login
                </p>
              </div>
            </div>
          </div>
          {this.state.isLoading ? (
            <div className="sweet-loading">
              <ClipLoader size={50} color={"#ffffff"} loading={true} />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
