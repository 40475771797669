import React, { Component } from "react";
import Header from "./Header";
import "./SubscriptionPage.css";
import { ReactComponent as BackArrowImage } from "../../assets/images/backArrow.svg";
import left_arrow from "../../assets/images/left-arrow.svg";
import mockup from "../../assets/images/mockup.png";
import { AiFillCheckCircle } from "react-icons/ai";
import qs from "qs";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Helmet} from "react-helmet";

export default class SubscriptionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      planDetails: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    this.setState({
      token: localStorage.getItem("token"),
    });
    this.getAllPlans();
  }
  getAllPlans = () => {
    this.setState({
      isLoading: true,
    });
    const url = "https://maps-surfer-api.surfable.co/subscription/allplans";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };
    console.log(config);

    axios
      .get(url, config)
      .then((result) => {
        this.setState({
          planDetails: result.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          // toast.error(`${error.response.data.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          // toast.error("Oops Something Went Wrong");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // toast.error("Oops Something Went Wrong");
        }

        this.setState({
          isLoading: false,
        });
      });
  };
  goToSWellMapOverview() {
    if (window.location.pathname == "/subscription") {
      this.props.history.push("/");
    } else {
      const locaion_name = this.props.match.params.location_name;
      this.props.history.push(`/swell_map/${locaion_name}`);
      window.location.reload();
    }
  }
  goToStripePage = (planName, price) => {
    let token = localStorage.getItem("token");
    if (token == null) {
      toast.warning(
        "Please login to continue...",
        {
          autoClose: 4000,
        },
        this.props.history.push(`/login`)
      );
    } else {
      console.log(price, planName);
      localStorage.setItem("plan", planName);
      localStorage.setItem("price",price)
      this.props.history.push(`/stripe/${planName}/${price}`);
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Subscription | SURFERforecast Maps</title>
          <meta name="description" content=" Subscription Pricing for 7-Day SURFERforecast Map Premium Access"></meta>
        
        </Helmet>
       <Header /> 
        <div>
          <div className="subscription-container">
            <div className="sub-main-container">
              <div className="sub-main">
                <div className="subscription-left-side">
                  <div className="subs-left-arrow-align">
                    <img
                      src={left_arrow}
                      onClick={() => this.goToSWellMapOverview()}
                      className="subs-left-arrow"
                    />
                    <p className="subscription-top-text">
                      Back to{" "}
                      {window.location.pathname == "/subscription"
                        ? "Home"
                        : "Forecast"}
                    </p>
                  </div>
                  <div className="mockup-wrap">
                    <img src={mockup} />
                  </div>
                  {/* <p className="subs-left-title">Subscribe Now!</p>
                <p className="subs-left-subtext">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmo adipiscing .
                </p> */}
                </div>
                <div className="subscription-right-side">
                  <p className="subs-left-title">Subscribe Now!</p>
                  <p className="subs-left-subtext">
                    <b>
                      {" "}
                     10 day surf wave height & period maps for Southern California, Northern California and Hawaii
                    </b>
                  </p>
                  <div className="subs-card">
                    <div className="subs-card-align">


                      <div className="cards">
                        {this.state.planDetails.map((data, i) => (
                          <div className="cards-wrap" id={`cw${i}`}>
                            <div className="first-card">
                              <p
                                className="subs-plan-subtext"
                                style={{ textTransform: "capitalize" }}
                              >
                                {" "}
                                {data.planName}
                                {data.planName === 'year' || data.planName === 'month' ? <br /> : ''}
                                {data.planName === 'year' ? '(12 Months)' : ' '}
                                {data.planName === 'month' ? '(30 Days)' : ' '}
                              </p>
                              <br></br>
                              <p className="subs-plan-title">
                                $ {data.price}
                                <span
                                  style={{
                                    fontSize: "0.9em",
                                    fontFamily: "Source-REg",
                                    textAlign: "center",
                                    color: "",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  / {data.planName}
                                </span>
                              </p>

                              <div className="subscription-btn-align">
                                <button
                                  className="subscribe-btn"
                                  onClick={() =>
                                    this.goToStripePage(
                                      data.planName,
                                      data.price
                                    )
                                  }
                                >
                                  Subscribe
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="subs-card-left-Align">
                        <p className="subs-right-text">Free</p>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Free 3 Day forecasts
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Free 7 Day wind forecasts 
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                    
                      
                      </div>
                      <div className="subs-card-left-Align">
                        <p className="subs-right-text">Premium</p>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            30 Day Free Trial for 10 day forecasts
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Monthly Membership unlocks access to all 10 day swell forecasts
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Zoom into your local spot on the shoreline to see the nearshore forecast
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            The Oahu Northshore map below has the best swell resolution of all our maps
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                       
                      </div>



                      <div
                        style={{
                          marginBottom: "15px",
                        }}
                      >
                        <div className="radio-text-align">
                          <p className="subs-right-subtext">
                            <b>Pricing : </b>
                          </p>
                        </div>
                        <p className="subs-right-subtext">We require a credit card to sign up for a free trial, but we will will send you a reminder email to help you remember when your trial is over. If you don't like our forecast tools, you can cancel without penalty.</p>
                       <br />
                        <p className="subs-right-subtext">
                        We are a super small team trying to build forecast tools to help you surf more. We are a startup and sometimes there will be bugs in our tools, but we appreciate your feedback, as it helps us build more features for you.
                        </p>
                        <br />
                        <p className="subs-right-subtext">
                        SURFERforecast is subscriber-supported and relies on surfers/supports like you. Your support is greatly appreciated!
                        </p>
                      <br></br>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            30 Day Free Trial
                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            1 Month Subscription: $2.99 US
                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            12 Month Subscription $29.99 US
                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            10 Day forecasts are only available to SURFER Maps Premium Members.
                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Purchase a Monthly Premium Membership Subscription to unlock unlimited access to all 10-Day SURFER forecast maps. Monthly Access is available for $2.99/mo which provides access for 30 days.


                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#F76C6C"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Monthly subscription automatically renews every 30 days. Annual subscription automatically renews every 12 months.


                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.isLoading ? (
            <div className="sweet-loading">
              <ClipLoader size={50} color={"#ffffff"} loading={true} />
            </div>
          ) : null
        }
      </div >
    );
  }
}
