
import React, { Component } from "react";
import logo from "../../assets/images/logo.png";
import "./sideBar.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import close from "../../assets/images/close.png";

import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      isHomeActive: false,
      isPricingActive: false,
      isLoginActive: false,
      isRegisterActive: false,
      isSCActive: false,
      isMobileAppActive: false,
      isBackToSurferActive: false,
      isAllForecastsActive: false,
      isMySubscription: false,
      showSubscribe: false
    };
  }
  gotToDashboard = (history) => {
    history.push("/");
  };

  componentDidMount() {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    this.setState({
      name: name,
      email: email,
      token: token,
      isPricingActive:
        window.location.pathname == "/subscription" ? true : false,
      isHomeActive: window.location.pathname == "/" ? true : false,
      isLoginActive: window.location.pathname == "/login" ? true : false,
      isRegisterActive: window.location.pathname == "/register" ? true : false,
      isSCActive: window.location.pathname == "/swell_map/socal" ? true : false,
      isMySubscription:
        window.location.pathname == "/my_subscriptions" ? true : false,
    });
  }
  onLogout = (history) => {
    const url = "https://maps-surfer-api.surfable.co/user/logout";

    const token = localStorage.getItem("token");
    console.log("tokkk", this.state.token);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + this.state.token,
      },
    };

    axios
      .get(url, config)
      .then((result) => {
        console.log(result.status);

        toast.info("Logged Out Successfully", {
          autoClose: 2000,
        });
        // setTimeout( () => { this.props.history.push("/")},2000);
        if (result.status == 200) {
          localStorage.clear();

          history.push("/");
          this.setState({
            token: null,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          toast.error(`${error.response.data.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        this.setState({
          isLoading: false,
        });
      });
  };
  goToHome = (history) => {
    this.setState({
      isHomeActive: true,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    history.push("/");
  };
  goToLogin = (history) => {
    this.setState({
      isHomeActive: true,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    history.push("/login");
  };
  goToRegister = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: true,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    history.push("/register");
  };
  goToMySubscriptions = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: true,
    });
    history.push("/my_subscriptions");
  };
  goToSouthernCalifornia = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: true,
      isMySubscription: false,
    });

    history.push("/swell_map/socal");
    window.location.reload();
  };

  goToPricing = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: true,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    history.push("/subscription");
  };
  goToAllForecats = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: true,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    window.open("https://forecast.surfer.com/");
  };
  goToMobileApp = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isMobileAppActive: true,
      isSCActive: false,
      isMySubscription: false,
      isMySubscription: false,
    });
    window.open("https://forecast.surfer.com/mobile-app");
  };
  goToBackToSurfer = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: true,
      isAboutActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });

    // window.open("https://www.surfer.com/",'_self')
    window.open("https://www.surfer.com/");
  };
  goToAbout = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: true,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });

    window.open("https://forecast.surfer.com/about/", "_blank");
  };

  getSubscriptionPage = () => {
    this.setState({
      isLoading: true,
    });

    const url =
      "https://maps-surfer-api.surfable.co/subscription/customerportel";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };

    axios
      .post(url, {}, config)
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data.session;
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {

        this.setState({
          isLoading: false,
          showSubscribe: true
        });
      });
  };
  hideSubscribePop() {
    this.setState({
      showSubscribe: false
    })
  }

  render() {
    return (
      <nav className="navbar navbar-inverse">
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#myNavbar"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Route
              render={({ history }) => (
                <img
                  id="page-wrap"
                  src={logo}
                  className="headerLogo"
                  onClick={() => this.gotToDashboard(history)}
                />
              )}
            />
          </div>
          <div className="collapse navbar-collapse" id="myNavbar">
            <ul className="nav navbar-nav">
              {this.state.token == null ? (
                <Route
                  render={({ history }) => (
                    <li
                      className={this.state.isLoginActive ? "active" : null}
                      onClick={() => this.goToLogin(history)}
                    >
                      <a>Login</a>
                    </li>
                  )}
                />
              ) : null}
              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isHomeActive ? "active" : null}
                    onClick={() => this.goToHome(history)}
                  >
                    <a>Swell Maps</a>
                  </li>
                )}
              />
              {this.state.token !== null ? (
                <>
                  <Route
                    render={({ history }) => (
                      <li
                        className={
                          this.state.isMySubscription ? "active" : null
                        }
                        onClick={() => this.getSubscriptionPage()}
                      >
                        <a>Premium</a>
                      </li>
                    )}
                  />
                </>
              ) : null}

              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isSCActive ? "active" : null}
                    onClick={() => this.goToSouthernCalifornia(history)}
                  >
                    <a>Southern California</a>
                  </li>
                )}
              />
              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isPricingActive ? "active" : null}
                    onClick={() => this.goToPricing(history)}
                  >
                    <a>Pricing</a>
                  </li>
                )}
              />
              {this.state.token == null ? (
                <Route
                  render={({ history }) => (
                    <li
                      className={this.state.isRegisterActive ? "active" : null}
                      onClick={() => this.goToRegister(history)}
                    >
                      <a>Register</a>
                    </li>
                  )}
                />
              ) : null}
              <Route
                render={({ history }) => (
                  <li
                    className={
                      this.state.isAllForecastsActive ? "active" : null
                    }
                    onClick={() => this.goToAllForecats(history)}
                  >
                    <a>All Forecasts</a>
                  </li>
                )}
              />
              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isMobileAppActive ? "active" : null}
                    onClick={() => this.goToMobileApp(history)}
                  >
                    <a>Mobile App</a>
                  </li>
                )}
              />
              <Route
                render={({ history }) => (
                  <li
                    className={
                      this.state.isBackToSurferActive ? "active" : null
                    }
                    onClick={() => this.goToBackToSurfer(history)}
                  >
                    <a>Back To Surfer</a>
                  </li>
                )}
              />
              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isAboutActive ? "active" : null}
                    onClick={() => this.goToAbout(history)}
                  >
                    <a>About</a>
                  </li>
                )}
              />
              {this.state.token !== null ? (
                <Route
                  render={({ history }) => (
                    <li onClick={() => this.onLogout(history)}>
                      <a>Logout</a>
                    </li>
                  )}
                />
              ) : null}
            </ul>
          </div>
        </div>
        {this.state.showSubscribe ? <div className="popup-container" onClick={() => this.hideSubscribePop()}>
          <div className="popup-outer" onClick={(e) => e.stopPropagation()}>
            <img src={close} className="close-icon" onClick={() => this.hideSubscribePop()} />
            <h2>Subscribe Now!</h2>
            <p>7 day surf wave height & period maps for Southern California, Northern California and Hawaii</p>
            <button className="subscribe-btn" onClick={() => window.location.href = '/subscription'}>Subscribe</button>
          </div>
        </div> : null}
      </nav>
    );
  }
}


// import React from "react";
// import { slide as Menu } from "react-burger-menu";
// import "./sideBar.css";
// import { GoPerson } from "react-icons/go";
// import { BiLogOut, BiLogIn } from "react-icons/bi";
// import { BsPersonCheckFill, BsCreditCard } from "react-icons/bs";
// import { AiFillHome, AiOutlineHome } from "react-icons/ai";
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import { IoIosPricetag } from "react-icons/io";
// import { SiSeat } from "react-icons/si";
// import { DiGhostSmall } from "react-icons/di";
// import { IoMdApps } from "react-icons/io";
// import { FcAbout } from "react-icons/fc";
// import { GiWaveSurfer } from "react-icons/gi";
// import { RiTreasureMapLine } from "react-icons/ri";
// import { TiInfoOutline } from "react-icons/ti";
// import axios from "axios";
// import "react-toastify/dist/ReactToastify.css";
// toast.configure();
// export default class sideBar extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       name: "",
//       email: "",
//       token: "",
//       isHomeActive: false,
//       isPricingActive: false,
//       isLoginActive: false,
//       isRegisterActive: false,
//       isSCActive: false,
//       isMobileAppActive: false,
//       isBackToSurferActive: false,
//       isAllForecastsActive: false,
//       isMySubscription:false
//     };
//   }
//   componentDidMount() {
//     const token = localStorage.getItem("token");
//     const name = localStorage.getItem("name");
//     const email = localStorage.getItem("email");
//     this.setState({
//       name: name,
//       email: email,
//       token: token,
//       isPricingActive:
//         window.location.pathname == "/subscription" ? true : false,
//       isHomeActive: window.location.pathname == "/" ? true : false,
//       isLoginActive: window.location.pathname == "/login" ? true : false,
//       isRegisterActive: window.location.pathname == "/register" ? true : false,
//       isSCActive: window.location.pathname == "/swell_map/socal" ? true : false,
//       isMySubscription:window.location.pathname=="/my_subscriptions"?true:false
//     });
//   }
//   onLogout = (history) => {
//     const url = "http://surf-maps-bk.devtomaster.com/user/logout";

//     const token = localStorage.getItem("token");
//     console.log("tokkk", this.state.token);

//     const config = {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//         Authorization: "Bearer" + this.state.token,
//       },
//     };

//     axios
//       .get(url, config)
//       .then((result) => {
//         console.log(result.status);

//         toast.info("Logged Out Successfully", {
//           autoClose: 2000,
//         });
//         // setTimeout( () => { this.props.history.push("/")},2000);
//         if (result.status == 200) {
//           localStorage.clear();

//           history.push("/");
//           this.setState({
//             token: null,
//           });
//         }
//       })
//       .ca// import React from "react";
// import { slide as Menu } from "react-burger-menu";
// import "./sideBar.css";
// import { GoPerson } from "react-icons/go";
// import { BiLogOut, BiLogIn } from "react-icons/bi";
// import { BsPersonCheckFill, BsCreditCard } from "react-icons/bs";
// import { AiFillHome, AiOutlineHome } from "react-icons/ai";
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import { IoIosPricetag } from "react-icons/io";
// import { SiSeat } from "react-icons/si";
// import { DiGhostSmall } from "react-icons/di";
// import { IoMdApps } from "react-icons/io";
// import { FcAbout } from "react-icons/fc";
// import { GiWaveSurfer } from "react-icons/gi";
// import { RiTreasureMapLine } from "react-icons/ri";
// import { TiInfoOutline } from "react-icons/ti";
// import axios from "axios";
// import "react-toastify/dist/ReactToastify.css";
// toast.configure();
// export default class sideBar extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       name: "",
//       email: "",
//       token: "",
//       isHomeActive: false,
//       isPricingActive: false,
//       isLoginActive: false,
//       isRegisterActive: false,
//       isSCActive: false,
//       isMobileAppActive: false,
//       isBackToSurferActive: false,
//       isAllForecastsActive: false,
//       isMySubscription:false
//     };
//   }
//   componentDidMount() {
//     const token = localStorage.getItem("token");
//     const name = localStorage.getItem("name");
//     const email = localStorage.getItem("email");
//     this.setState({
//       name: name,
//       email: email,
//       token: token,
//       isPricingActive:
//         window.location.pathname == "/subscription" ? true : false,
//       isHomeActive: window.location.pathname == "/" ? true : false,
//       isLoginActive: window.location.pathname == "/login" ? true : false,
//       isRegisterActive: window.location.pathname == "/register" ? true : false,
//       isSCActive: window.location.pathname == "/swell_map/socal" ? true : false,
//       isMySubscription:window.location.pathname=="/my_subscriptions"?true:false
//     });
//   }
//   onLogout = (history) => {
//     const url = "http://surf-maps-bk.devtomaster.com/user/logout";

//     const token = localStorage.getItem("token");
//     console.log("tokkk", this.state.token);

//     const config = {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//         Authorization: "Bearer" + this.state.token,
//       },
//     };

//     axios
//       .get(url, config)
//       .then((result) => {
//         console.log(result.status);

//         toast.info("Logged Out Successfully", {
//           autoClose: 2000,
//         });
//         // setTimeout( () => { this.props.history.push("/")},2000);
//         if (result.status == 200) {
//           localStorage.clear();

//          tch((error) => {
//         if (error.response) {
//           // Request made and server responded
//           console.log(error.response.data);
//           toast.error(`${error.response.data.data}`);
//           console.log(error.response.status);
//           console.log(error.response.headers);
//         } else if (error.request) {
//           // The request was made but no response was received
//           console.log(error.request);
//         } else {
//           // Something happened in setting up the request that triggered an Error
//           console.log("Error", error.message);
//         }
//         this.setState({
//           isLoading: false,
//         });
//       });
//   };
//   goToHome = (history) => {
//     this.setState({
//       isHomeActive: true,
//       isPricingActive: false,
//       isRegisterActive: false,
//       isLoginActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//       isMySubscription:false
//     });
//     history.push("/");
//   };
//   goToLogin = (history) => {
//     this.setState({
//       isHomeActive: true,
//       isPricingActive: false,
//       isRegisterActive: false,
//       isLoginActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//       isMySubscription:false
//     });
//     history.push("/login");
//   };
//   goToRegister = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isRegisterActive: true,
//       isLoginActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//       isMySubscription:false
//     });
//     history.push("/register");
//   };
//   goToMySubscriptions = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isRegisterActive: false,
//       isLoginActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//       isMySubscription:true
//     });
//     history.push("/my_subscriptions");
//   };
//   goToSouthernCalifornia = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isRegisterActive: false,
//       isLoginActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: true,
//       isMySubscription:false
//     });

//     history.push("/swell_map/socal");
//     window.location.reload();
//   };

//   goToPricing = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: true,
//       isRegisterActive: false,
//       isLoginActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//       isMySubscription:false
//     });
//     history.push("/subscription");
//   };
//   goToAllForecats = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isRegisterActive: false,
//       isLoginActive: false,
//       isAllForecastsActive: true,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//       isMySubscription:false
//     });
//     window.open("https://forecast.surfer.com/");
//   };
//   goToMobileApp = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isRegisterActive: false,
//       isLoginActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: false,
//       isMobileAppActive: true,
//       isSCActive: false,
//       isMySubscription:false ,
//       isMySubscription:false
//     });
//     window.open("https://forecast.surfer.com/mobile-app");
//   };
//   goToBackToSurfer = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isRegisterActive: false,
//       isLoginActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: true,
//       isAboutActive: false,
//       isMobileAppActive: false,
//       isSCActive: false,
//       isMySubscription:false
//     });

//     // window.open("https://www.surfer.com/",'_self')
//     window.open("https://www.surfer.com/");
//   };
//   goToAbout = (history) => {
//     this.setState({
//       isHomeActive: false,
//       isPricingActive: false,
//       isRegisterActive: false,
//       isLoginActive: false,
//       isAllForecastsActive: false,
//       isBackToSurferActive: false,
//       isAboutActive: true,
//       isMobileAppActive: false,
//       isSCActive: false,
//       isMySubscription:false
//     });

//     window.open("https://forecast.surfer.com/about/", "_blank");
//   };

//   render() {
//     console.log(this.state.token);
//     return (
//       <Menu>
//         {this.state.token == null || this.state.token == "null" ? (
//           <>
//             <div>
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isLoginActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToLogin(history)}
//                   >
//                     {/* <BiLogIn
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Login
//                     </a>
//                   </div>
//                 )}
//               />

//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isHomeActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToHome(history)}
//                   >
//                     {/* <AiOutlineHome
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Swellmaps
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isSCActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToSouthernCalifornia(history)}
//                   >
//                     {/* <RiTreasureMapLine
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Southern California
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isPricingActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToPricing(history)}
//                   >

//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Pricing
//                     </a>
//                   </div>
//                 )}
//               />

//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isRegisterActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToRegister(history)}
//                   >

//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Register
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isAllForecastsActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToAllForecats(history)}
//                   >

//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       All Forecasts
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isMobileAppActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToMobileApp(history)}
//                   >

//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Mobile App
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isBackToSurferActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToBackToSurfer(history)}
//                   >

//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Back to Surfer
//                     </a>
//                   </div>
//                 )}
//               />

//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isAboutActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToAbout(history)}
//                   >

//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       About
//                     </a>
//                   </div>
//                 )}
//               />
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="side-barround-user-icon">
//               <GoPerson size="30px" className="user-icon" color=" #000000" />
//             </div>
//             <p className="side-bar-username">{this.state.name}</p>
//             <p className="side-bar-userEmail">{this.state.email}</p>

//             <div className="logout-menu">

//             <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isHomeActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToHome(history)}
//                   >
//                     {/* <AiOutlineHome
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Swellmaps
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isSCActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToSouthernCalifornia(history)}
//                   >
//                     {/* <RiTreasureMapLine
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Southern California
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isPricingActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToPricing(history)}
//                   >
//                     {/* <IoIosPricetag
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Pricing
//                     </a>
//                   </div>
//                 )}
//               />
//               {/* <Route
//               render={({ history }) => (
//                 <div
//                   className="logout-menu-item"
//                   style={{marginTop:"0px"}}
//                   onClick={() => this.goToMySubscriptions(history)}
//                 >
//                   <BsCreditCard
//                     color="#f76c6c"
//                     size="19px"
//                     className="sidebar-logout-icon"
//                   />
//                   <a
//                     style={{
//                       color: "white",
//                       cursor: "pointer",
//                       marginLeft: "8px",
//                     }}
//                   >
//                     {" "}
//                     My Subscriptions
//                   </a>
//                 </div>
//               )}
//             /> */}

//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isAllForecastsActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToAllForecats(history)}
//                   >
//                     {/* <DiGhostSmall
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       All Forecasts
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isMobileAppActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToMobileApp(history)}
//                   >
//                     {/* <IoMdApps
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Mobile App
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isBackToSurferActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToBackToSurfer(history)}
//                   >
//                     {/* <GiWaveSurfer
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Back to Surfer
//                     </a>
//                   </div>
//                 )}
//               />

//               <Route
//                 render={({ history }) => (
//                   <div
//                     className={
//                       this.state.isAboutActive
//                         ? "logout-menu-Item menu-item-active"
//                         : "logout-menu-Item"
//                     }
//                     onClick={() => this.goToAbout(history)}
//                   >
//                     {/* <TiInfoOutline
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       About
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                   className={
//                     this.state.isMySubscription
//                       ? "logout-menu-Item menu-item-active"
//                       : "logout-menu-Item"
//                   }
//                     onClick={() => this.goToMySubscriptions(history)}
//                   >
//                     {/* <BsCreditCard
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       My Subscriptions
//                     </a>
//                   </div>
//                 )}
//               />
//               <Route
//                 render={({ history }) => (
//                   <div
//                     className="logout-menu-item"
//                     onClick={() => this.onLogout(history)}
//                   >
//                     {/* <BiLogOut
//                       color="#f76c6c"
//                       size="19px"
//                       className="sidebar-logout-icon"
//                     /> */}
//                     <a
//                       style={{
//                         color: "white",
//                         cursor: "pointer",
//                         marginLeft: "8px",
//                       }}
//                     >
//                       {" "}
//                       Logout
//                     </a>
//                   </div>
//                 )}
//               />

//             </div>
//           </>
//         )}
//       </Menu>
//     );
//   }
// }

